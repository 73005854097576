import React, { useRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { TRACTS_TABLE_QUERY } from '../../components/tracts/TractQueries';
import LoadingView from '../../components/LoadingView';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
// Currently all the pagination stuff is commented out
// import { getPageNumberFromQueryParams } from '../../util/Utils';
// import queryString from 'query-string';

// Helper to map color codes
function getColorStyle(color) {
  switch (color) {
    case 'M&S':
      return { backgroundColor: 'rgba(255,255,0,0.53)', color: 'black' };
    case 'M':
      return { backgroundColor: 'rgba(255,0,0,0.2)', color: 'black' };
    case 'S':
      return { backgroundColor: 'rgba(0,0,255,0.2)', color: 'black' };
    case 'All':
      return { backgroundColor: 'rgba(0,200,255,0.2)', color: 'black' };
    default:
      return { };
  }
}
const anyStyles = makeStyles(() => ({
  buttons: {
    cursor: 'pointer',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
  },
}));


// Parse fractional strings like "3/8", "1/4", or "1"
function fractionToNumber(fracStr) {
  if (!fracStr) return 0;
  if (fracStr.includes('/')) {
    const [numerator, denominator] = fracStr.split('/');
    return parseFloat(numerator) / parseFloat(denominator);
  }
  return parseFloat(fracStr);
}


const LandsColumns = [
  {
    title: 'FAMILY ID',
    field: 'id',
    render: (rowData) => (
      <Link
        to={`/Lands/${rowData.id}?name=${encodeURIComponent(rowData.id)}`}
        target="_blank"
        style={getColorStyle(rowData.color)}
      >
        {rowData.id}
      </Link>
    ),
  },
  { title: 'TYPE', field: 'color' },
  {
    title: 'NMA',
    field: 'familyNMA',
    render: (rowData) => parseFloat(rowData.familyNMA).toFixed(8)
  },
  {
    title: 'NSA',
    field: 'familyNSA',
    render: (rowData) => parseFloat(rowData.familyNSA).toFixed(8)
  },
  {
    title: 'MES %',
    field: 'mesPercent',
    render: (rowData) => rowData.mesPercent.toFixed(2)
  },
  {
    title: 'MPI %',
    field: 'mpiPercent',
    render: (rowData) => rowData.mpiPercent.toFixed(2)
  },
  {
    title: 'SRO %',
    field: 'sroPercent',
    render: (rowData) => rowData.sroPercent.toFixed(2)
  }
];
function simple(type) {return type ? type : 'ALL'}

function LandsTable({ history }) {
  const classes = anyStyles(undefined);
  const { loading, error, data } = useQuery(TRACTS_TABLE_QUERY);
  const [selectedColor, setSelectedColor] = useState(null); // default filter
  // const [currentPageSize, setCurrentPageSize] = useState(null); // set the page size based on the current filter being used

  // const [, setPageNumber] = useState(
  //   getPageNumberFromQueryParams(queryString.parse(window.location.search))
  // );

  const tableRef = useRef(null);
  const handleSelectChange = (event) => {
    const value = event.target.value;
    console.log(value, 'THIS IS THE VALUE SELECTED')
    switch (value) {
      case 'ALL':
        setSelectedColor(null);
        // setCurrentPageSize(countAll);
        break;
      default:
        setSelectedColor(value);
        break;
    }
  };

  // Make sure pageSize is at least 1
  // const calculatedPageSize = Math.max(
  //   1,
  //   Math.floor((window.innerHeight - 300) / 40)
  // );
  //
  // useEffect(() => {
  //   if (tableRef.current?.dataManager) {
  //     tableRef.current.dataManager.changePageSize(calculatedPageSize);
  //   }
  // }, [calculatedPageSize]);

  if (error) return <h1>Can’t Load Tracts!</h1>;
  if (loading) return <LoadingView />;

  const allTracts = data.Tract;
  const countAll = allTracts.length;
  const countMS = allTracts.filter(t => t.color === 'M&S').length;
  const countS  = allTracts.filter(t => t.color === 'S').length;
  const countM  = allTracts.filter(t => t.color === 'M').length;

  // 1) Filter the top-level array by color (M, S, or M&S)
  const filteredTracts =
    selectedColor
      ? data.Tract.filter(t => t.color === selectedColor)
      : data.Tract;

  const tableData = filteredTracts.map((tract) => {
    // Aggregate mineral fractions by owner type
    const mesTotal = tract.mineralsOwned
      .filter(o => o.Owner.name === 'MES')
      .reduce((sum, o) => sum + fractionToNumber(o.mineralFraction), 0);

    const mpiTotal = tract.mineralsOwned
      .filter(o => o.Owner.name === 'MPI')
      .reduce((sum, o) => sum + fractionToNumber(o.mineralFraction), 0);

    const sroTotal = tract.mineralsOwned
      .filter(o => o.Owner.name === 'SRO')
      .reduce((sum, o) => sum + fractionToNumber(o.mineralFraction), 0);

    return {
      id: tract.id,
      color: tract.color,
      familyNMA: tract.familyNMA,
      familyNSA: tract.familyNSA,
      grossMineralAcres: tract.grossMineralAcres,
      grossSurfaceAcres: tract.grossSurfaceAcres,
      mesPercent: mesTotal * 100,
      mpiPercent: mpiTotal * 100,
      sroPercent: sroTotal * 100
    };
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>

      {/* RIGHT SIDE: MaterialTable with the flattened data */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <MaterialTable
          tableRef={tableRef}
          data={tableData}
          columns={LandsColumns}
          title=""
          // onChangePage={(page) => setPageNumber(page)}
          options={{
            tableLayout: 'auto',
            paging: false,
            sorting: true,
            exportButton: true,
            exportAllData: true,
            // pageSize: currentPageSize ? currentPageSize : countAll,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: '#fff',
              color: '#000',
            },
            headerStyle: {
              backgroundColor: '#1976d2',
              color: '#FFF',
              textAlign: 'left',
              justifyContent: 'center',
              whiteSpace: 'pre',
            },
            toolbarButtonAlignment: 'left',
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: '#1976d2',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 16px',
                }}
              >
                <MTableToolbar {...props} />
                <select
                  onChange={handleSelectChange}
                  value={simple(selectedColor)}
                  style={{ padding: '0.25rem', minWidth: '100px'}}
                  className={`${classes.buttons}`}
                >
                  <option value="ALL">
                    ALL ({countAll})
                  </option>
                  <option value="M&S">
                    M&S ({countMS})
                  </option>
                  <option value="S">
                    S ({countS})
                  </option>
                  <option value="M">
                    M ({countM})
                  </option>
                </select>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => history.push('/addLand')}
                  style={{ marginLeft: '16px' }}
                >
                  Add Land
                </Button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default LandsTable;
