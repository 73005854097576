import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_CLAUSE, DELETE_CLAUSE, GET_LEASE, UPDATE_CLAUSE } from '../../components/leases/LeaseQueries';
import LoadingView from '../../components/LoadingView';
import { Field, Form, Formik } from 'formik';
import { leaseTemplateSchema } from '../../util/schema/LeaseSchema';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import ClauseTable from './ClauseTable';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const MaterialTableStyles = makeStyles((theme) => ({
  Switch: { margin: 0 },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    margin: 10,
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  FormControlLabel: { paddingRight: '5em' },
  Select: { display: 'block' },
  FullWidthTextBox: { marginBottom: 20, marginTop: 20 },
  paper: { padding: 25, marginBottom: '1rem', marginTop: '1rem' },
  TextField: { display: 'block', marginTop: 10, marginBottom: 10, paddingRight: '1em' },
  form: { marginTop: '20px' },
  title: { color: '#1976d2', fontSize: '1.75rem', textAlign: 'center', textDecoration: 'underline' },
  notes: { width: '100%', height: '100%' },
  main: { display: 'grid', gridTemplateColumns: '1fr 3fr' },
  landsTable: { display: 'none' },
}));

const EditLeaseTemplate = ({ match, history }) => {
  const classes = MaterialTableStyles();

  const LeaseClausesFragment = gql`
      fragment LeaseClausesFragment on Lease {
          id
          leaseClauses {
              id
              type
              name
              paragraph
              text
              oil
              gas
          }
      }
  `;

  const [createClause] = useMutation(CREATE_CLAUSE);
  const [deleteClause] = useMutation(DELETE_CLAUSE);
  const [updateClause] = useMutation(UPDATE_CLAUSE);

  const editClause = (values) => {
    return updateClause({ variables: values });
  };

  const removeClause = (clauseId, leaseId) => {
    return deleteClause({
      variables: { id: clauseId },
      update: (store) => {
        try {
          // Ensure leaseId matches cache format (e.g., "Lease:123")
          const cacheId = `Lease:${leaseId}`;
          const oldClauses = store.readFragment({
            id: cacheId,
            fragment: LeaseClausesFragment,
          });

          if (!oldClauses) {
            console.warn(`Fragment not found in cache for ID: ${cacheId}`);
            return; // Skip cache update if fragment isn’t cached
          }

          const updatedClauses = oldClauses.leaseClauses.filter((clause) => clause.id !== clauseId);

          store.writeFragment({
            id: cacheId,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseClauses: updatedClauses,
            },
          });
        } catch (e) {
          console.error('Error updating cache after delete:', e);
        }
      },
    });
  };

  const addClause = (values) => {
    return createClause({
      variables: values,
      update: (store, { data: { addClause } }) => {
        try {
          const cacheId = `Lease:${values.leaseID}`;
          const oldClauses = store.readFragment({
            id: cacheId,
            fragment: LeaseClausesFragment,
          });

          if (!oldClauses) {
            console.warn(`Fragment not found in cache for ID: ${cacheId}`);
            return; // Skip cache update if fragment isn’t cached
          }

          const updatedClauses = [...oldClauses.leaseClauses, addClause];

          store.writeFragment({
            id: cacheId,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseClauses: updatedClauses, // Fixed typo (was leaseRecordings)
            },
          });
        } catch (e) {
          console.error('Error updating cache after add:', e);
        }
      },
    });
  };

  const { data, loading, error } = useQuery(GET_LEASE, {
    variables: { id: match.params.leaseID },
  });

  if (loading) return <LoadingView />;
  if (error || !data?.Lease?.[0]) {
    console.error('Query error or no lease found:', error);
    return <div>Error: Failed to load lease template.</div>;
  }

  const Lease = data.Lease[0];

  return (
    <Formik
      initialValues={Lease}
      validationSchema={leaseTemplateSchema}
      onSubmit={(values) => {
        // No-op for now since there's no submit logic
      }}
    >
      {(props) => (
        <div>
          <Form>
            <Typography className={classes.title} color='textSecondary' gutterBottom>
              Edit Lease Template
            </Typography>
            <Field
              name='leaseName'
              placeholder='Lease ID'
              label='ID'
              component={TextField}
              className={classes.TextField}
              margin='normal'
              disabled={true}
            />
          </Form>
          <ClauseTable queries={{ editClause, removeClause, addClause }} Lease={Lease} />
          <Button
            variant='contained'
            color='primary'
            className={classes.SubmitButton}
            onClick={() => {
              history.push('/Leases');
            }}
            fullWidth
          >
            Finish
          </Button>
        </div>
      )}
    </Formik>
  );
};

export default EditLeaseTemplate;