import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useMutation, useApolloClient } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { CLONE_LEASE_CLAUSES, CREATE_LEASE, DELETE_LEASE, GET_ALL_LEASES } from '../../components/leases/LeaseQueries';
import { v4 } from 'uuid';
import LoadingView from '../../components/LoadingView';

const TemplateLeaseTable = ({ templateData, history }) => {
  const client = useApolloClient();

  const [tableData, setTableData] = useState(
    Array.isArray(templateData)
      ? templateData.map(item => ({
        ...item,
        leaseRecordings: item.leaseRecordings ? [...item.leaseRecordings] : [],
      }))
      : []
  );

  const [deleteLease] = useMutation(DELETE_LEASE, {
    update: (store, { data: { DeleteLease } }) => {
      try {
        const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });
        if (AllLeasesQuery) {
          const filteredLeases = AllLeasesQuery.Lease.filter(lease => lease.id !== DeleteLease.id);
          store.writeQuery({ query: GET_ALL_LEASES, data: { Lease: filteredLeases } });
        }
      } catch (e) {
        console.log('Cache update failed:', e);
      }
    },
  });

  const removeLease = values => deleteLease({ variables: values });

  const [clauseClone] = useMutation(CLONE_LEASE_CLAUSES);
  const cloneClauses = values => clauseClone({ variables: values });

  const columns = [
    {
      title: 'Lease',
      field: 'leaseName',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    },
  ];

  const tableUpdate = leaseID => setTableData(prev => prev.filter(lease => lease.id !== leaseID));

  if (!templateData || !Array.isArray(templateData)) {
    return <LoadingView />;
  }

  return (
    <Mutation mutation={CREATE_LEASE}>
      {(CreateLease, { loading, error }) => {
        if (loading) return <LoadingView />;
        if (error) console.log('CreateLease error:', error);


        return (
          <MaterialTable
            data={tableData.map(item => ({ ...item }))}
            columns={columns}
            title=""
            components={{
              Toolbar: props => (
                <div
                  style={{
                    backgroundColor: '#1976d2',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              headerStyle: { backgroundColor: '#1976d2', color: '#FFF', textAlign: 'left' },
              selection: false,
              exportButton: true,
              searchFieldStyle: {
                border: 'none',
                padding: '4px 8px',
                borderRadius: '4px',
                backgroundColor: '#fff',
                color: '#000',
              },
              pageSize: Math.max(tableData.length, 5),
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
              showTitle: false,
            }}
            editable={{
              onRowDelete: oldData =>
                removeLease({ id: oldData.id }).then(() => tableUpdate(oldData.id)),
            }}
            actions={[
              {
                icon: 'check',
                tooltip: 'Use Lease Template',
                onClick: (event, rowData) => {
                  const newId = v4();
                  const uniqueLeaseName = `LeaseTemplate-${newId.slice(0, 8)}`;
                  const useTemplateData = {
                    ...rowData,
                    id: newId,
                    leaseName: uniqueLeaseName,
                    template: false,
                  };

                  CreateLease({
                    variables: useTemplateData,
                    update: (store, { data: { CreateLease: createdLease } }) => {
                      try {
                        const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });
                        if (AllLeasesQuery) {
                          const data = { Lease: [...AllLeasesQuery.Lease, createdLease] };
                          store.writeQuery({ query: GET_ALL_LEASES, data });
                        }
                      } catch (e) {
                        console.log('Cache update failed:', e);
                      }
                    },
                  })
                    .then(({ data }) => {
                      if (!data || !data.CreateLease) {
                        throw new Error('Lease creation failed');
                      }
                      return cloneClauses({
                        leaseID: newId,
                        clauses: rowData.leaseClauses?.map(c => c.id) || [],
                      });
                    })
                    .then(() => {
                      console.log('Lease created, redirecting to:', `/Leases/${newId}`);
                      history.push(`/Leases/${newId}?name=${encodeURIComponent(rowData.leaseName || uniqueLeaseName)} (TEMPLATE)`);
                    })
                    .catch(err => {
                      console.log('Error cloning lease:', err);
                      alert(
                        err.message.includes('already exists')
                          ? `A lease with name "${uniqueLeaseName}" already exists. Try again.`
                          : 'Failed to create lease. Please try again.'
                      );
                    });
                },
              },
              {
                icon: 'edit',
                tooltip: 'Edit Lease Template',
                onClick: (event, rowData) =>
                  history.push(`/EditLeaseTemplate/${rowData.id}?name=${encodeURIComponent(rowData.leaseName)}`),
              },
            ]}
          />
        );
      }}
    </Mutation>
  );
};

export default TemplateLeaseTable;