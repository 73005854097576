import React from 'react';
import { TextField, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { useFormik } from 'formik';

// Define the theme
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTextField: {
      root: {
        marginRight: 16, // Space between fields
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 36, // Smaller height for TextField
        backgroundColor: 'white', // White field background
        '& fieldset': {
          borderColor: '#ccc', // Subtle border
        },
        '&:hover fieldset': {
          borderColor: '#999', // Hover state
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1976d2', // Match form background on focus
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 10px) scale(1)', // Adjust label position
        fontSize: '0.9rem', // Smaller label
        color: '#667', // Subtle label color
        '&.Mui-focused': {
          transform: 'translate(14px, -6px) scale(0.75)', // Shrink on focus
          color: 'white', // Match form background
        },
      },
    },
  },
});

const CreateUserForm = ({ mutations: { CreateUser }, enqueueSnackbar }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, { resetForm }) => {
      CreateUser({ variables: { email: values.email, password: values.password } })
        .then((res) => {
          enqueueSnackbar('Successfully created account!', { variant: 'success' });
          resetForm();
        })
        .catch((err) => {
          console.error(err.message);
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <form
        id="addUser"
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#1976d2', // Blue background
          padding: '8px 16px', // Compact padding
        }}
      >
        <TextField
          label="Email"
          type="email"
          autoComplete="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          variant="outlined"
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          variant="outlined"
        />
      </form>
    </MuiThemeProvider>
  );
};

export default CreateUserForm;