import React, { useState } from 'react';
import { Button, InputLabel, FormLabel, MenuItem } from '@material-ui/core';
import { TextField, Switch } from 'formik-material-ui';
import { Form, Field, Formik } from 'formik';
import _ from 'underscore';
import WellMapCard from '../../maps/WellMapCard';
import { WellSchema } from '../../../util/schema/WellSchema';
import DIWellModal from '../DIWellModal';
import DeleteWellModal from '../DeleteWellModal';
import { Prompt } from 'react-router';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { WellFormStyles } from '../WellTabPage';
import { Link } from 'react-router-dom';
import { wellStatuses } from "../../../util/Statuses";

const WellDetailForm = ({ Well, TractNames = [], Operators = [], enqueueSnackbar, history, onSuccess, DeleteWell }) => {
  const classes = WellFormStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const divisionOrderComplete = !Well.divisionOrder ? false : Well.divisionOrder.length === Well.drilledOn.length;

  const totalDOI = (ownerName = '') => {
    return Well.divisionOrder.reduce(function(a, v) {
      return a + v[ownerName];
    }, 0);
  };

  const mesDOI = divisionOrderComplete ? totalDOI('mesDOI').toFixed(8) : 'Incomplete';
  const sroDOI = divisionOrderComplete ? totalDOI('sroDOI').toFixed(8) : 'Incomplete';
  const mpiDOI = divisionOrderComplete ? totalDOI('mpiDOI').toFixed(8) : 'Incomplete';

  return (
    <div>
      <Formik
        initialValues={{
          ...Object.fromEntries(
            Object.entries(Well).map(([key, value]) => [key, value === null ? '' : value])
          ),
          sroDOI: sroDOI || '',
          mesDOI: mesDOI || '',
          mpiDOI: mpiDOI || ''
        }}
        validationSchema={WellSchema}
        onSubmit={(values, actions) => {
          WellSchema.validate(values, { stripUnknown: true })
            .then((WellValues) => WellSchema.cast(WellValues))
            .then((cleanedWell) => onSuccess(cleanedWell))
            .then((result) => actions.setSubmitting(false))
            .catch((err) => {
              console.error(err);

              actions.resetForm(values);

              enqueueSnackbar(err.toString(), { variant: 'error' });
            });
        }}
      >
        {(props) => {
          return (
            <>
              <Prompt
                when={props.dirty && props.submitCount === 0}
                message='Are you sure you want to leave? Your unsaved changes will be deleted'
              />
              <Form className={classes.root}>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {Well.name}
                </Typography>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {!Well.unit ? (
                    ''
                  ) : (
                    <Link
                      to={`/Units/${Well.unit.id}?name=${encodeURIComponent(Well.unit.name)}`}
                      target='_blank'>
                      {Well.unit.name}
                    </Link>
                  )}
                </Typography>
                <hr style={{ width: '80%' }} />
                <br />
                <div style={{ display: 'flex', direction: 'row' }}>
                  <Field
                    name='name'
                    placeholder='Name'
                    component={TextField}
                    label='Name'
                    className={classes.TextField}
                    style={{ width: 250 }}
                    fullWidth
                  />
                  <Field
                    name='apiNumber'
                    placeholder='API Number'
                    component={TextField}
                    label='API Number'
                    className={classes.TextField}
                  />
                  <Field
                    name='type'
                    placeholder='Type'
                    component={TextField}
                    label='Type'
                    select
                    className={classes.TextField}
                    style={{ width: '100px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value={'Oil'}>Oil</MenuItem>
                    <MenuItem value={'Gas'}>Gas</MenuItem>
                  </Field>

                  <Field
                    name='drillType'
                    placeholder='Drill Type'
                    component={TextField}
                    label='Drill Type'
                    className={classes.TextField}
                    style={{ width: '100px' }}
                  />
                  <Field
                    name='rrcLeaseNumber'
                    placeholder='RRC Lease Number'
                    component={TextField}
                    label='RRC Lease Number'
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field name='rrcField' label='RRC Field' component={TextField} className={classes.TextField} />
                  <Field
                    name='status'
                    placeholder='Status'
                    component={TextField}
                    label='Status'
                    select
                    className={classes.TextField}
                    style={{ width: '100px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {
                      Object.keys(wellStatuses).map((status) => {
                        return <MenuItem value={status} key={status}>{wellStatuses[status]}</MenuItem>
                      })
                    }
                  </Field>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 800 }}>
                    <InputLabel>Operated By</InputLabel>
                    <Field name='operatedBy' id='operatedBy'>
                      {({ field, form }) => (
                        <Select
                          value={field.value}
                          options={Operators}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(newOperatedBy) => {
                            form.setFieldValue(field.name, newOperatedBy);
                            form.setFieldValue('operator', newOperatedBy.name);
                          }}
                          styles={{
                            option: (provided, state) => ({ ...provided, fontFamily: 'Roboto' }),
                            singleValue: (provided, state) => ({ ...provided, fontFamily: 'Roboto' }),
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <Field
                    name='operatedBy.operatorNumber'
                    label='Operator Number'
                    component={TextField}
                    className={classes.TextField}
                    disabled
                    style={{ marginLeft: 20 }}
                  />
                </div>

                <div style={{ display: 'flex', direction: 'row' }}>
                  <Field
                    name='lengthOfLateral'
                    label='Length Of Lateral'
                    component={TextField}
                    className={classes.TextField}
                    style={{ width: 250 }}
                    fullWidth
                  />
                  <Field
                    name='operatorInterval'
                    component={TextField}
                    label='Operator Interval'
                    className={classes.TextField}
                  />
                  <Field name='lowerPerf' component={TextField} label='Lower Perf' className={classes.TextField} />
                  <Field name='upperPerf' component={TextField} label='Upper Perf' className={classes.TextField} />
                  <Field name='perfLength' component={TextField} label='Gross Perf' className={classes.TextField} />
                </div>
                <div style={{ display: 'block' }}>
                  <InputLabel htmlFor='landIDs' style={{ display: 'block' }}>
                    Land ID(s)
                  </InputLabel>
                  <Field name='drilledOn' id='landIDs'>
                    {({ field }) => (
                      <Select
                        value={field.value}
                        options={TractNames}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(newLandsArr) => {
                          props.setFieldValue(field.name, newLandsArr);
                          props.setFieldValue(
                            'landId',
                            newLandsArr.length !== 0 ? newLandsArr.map((land) => land.name).join(',') : ''
                          );
                        }}
                        styles={{
                          option: (provided, state) => ({ ...provided, fontFamily: 'Roboto' }),
                          singleValue: (provided, state) => ({ ...provided, fontFamily: 'Roboto' }),
                          multiValueLabel: (provided, state) => ({ ...provided, fontFamily: 'Roboto' }),
                        }}
                        isMulti
                      />
                    )}
                  </Field>
                </div>
                <div style={{ display: 'flex' }}>
                  <Field
                    name='state'
                    component={TextField}
                    label='State'
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: '75px' }}
                  />
                  <Field
                    name='county'
                    component={TextField}
                    label='County'
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: '100px' }}
                  />
                  <Field
                    name='abstract'
                    placeholder='Abstract'
                    component={TextField}
                    label='Abstract'
                    className={classes.TextField}
                    style={{ width: '100px' }}
                  />
                  <Field
                    name='familyID'
                    label='Family ID'
                    component={TextField}
                    className={classes.TextField}
                    fullWidth
                    style={{ width: 250 }}
                  />
                  <Field name='unit.name' label='Unit' component={TextField} className={classes.TextField} disabled />
                  <Field
                    name={!Well.lease ? 'OGL' : 'lease.leaseName'}
                    label='OGL'
                    component={TextField}
                    className={classes.TextField}
                    disabled
                    fullWidth
                    style={{ width: 250 }}
                  />
                  <Field
                    name={'unit.unitGMA'}
                    label='Acres'
                    component={TextField}
                    className={classes.TextField}
                    disabled
                    fullWidth
                    style={{ width: 100 }}
                  />
                  <Field
                    name={!Well.unit ? 'location' : 'unit.propertyDesc'}
                    label={!Well.unit ? 'Location' : 'Land Description'}
                    placeholder={!Well.unit ? 'Location' : 'Land Description'}
                    component={TextField}
                    className={classes.TextField}
                    fullWidth
                    style={{ width: 600 }}
                    disabled={!Well.unit ? false : true}
                  />
                </div>
                <Paper className={classes.paper}>
                  <Typography className={classes.title} color='textSecondary' gutterBottom>
                    In Process
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Field
                      name='permitDate'
                      placeholder='Permit Date'
                      component={TextField}
                      label='Permit Date'
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='permitNumber'
                      placeholder='Permit Number'
                      component={TextField}
                      label='Permit Number'
                      className={classes.TextField}
                    />
                    <Field
                      name='plat'
                      label='Plat'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='g1'
                      label='G-1'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='p4'
                      label='P-4'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='p12'
                      label='P-12'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Field
                      name='p16'
                      label='P-16'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='p17'
                      label='P-17'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='spudDate'
                      label='Spud Date'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='fracFocus'
                      label='Frac Focus'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='rigReleaseDate'
                      label='Rig Release Date'
                      placeholder='MM-DD-YYYY'
                      type='date'
                      component={TextField}
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='completionDate'
                      label='Completion Date'
                      placeholder='MM-DD-YYYY'
                      type='date'
                      component={TextField}
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Field
                      name='doto'
                      label='DOTO'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='completionApp'
                      label='Completion App'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='dateProducing'
                      label='Date Producing'
                      placeholder='MM-DD-YYYY'
                      type='date'
                      component={TextField}
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='dateInPay'
                      label='Date In Pay'
                      placeholder='MM-DD-YYYY'
                      type='date'
                      component={TextField}
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='mesDivOrder'
                      label='MES Div Order'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      name='mpiDivOrder'
                      label='MPI Div Order'
                      placeholder='MM-DD-YYYY'
                      component={TextField}
                      type='date'
                      className={classes.TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </Paper>
                <Paper className={classes.paper}>
                  <Typography className={classes.title} color='textSecondary' gutterBottom>
                    Payor
                  </Typography>
                  <div style={{ display: 'flex', direction: 'row' }}>
                    <Field name='oilPayor' label='Oil Payor' component={TextField} className={classes.TextField} />
                    <Field name='gasPayor' label='Gas Payor' component={TextField} className={classes.TextField} />
                    <Field
                      name='decProdUnit'
                      component={TextField}
                      label='Dec Prod Unit'
                      className={classes.TextField}
                    />
                    <Field disabled name='mesDOI' label='MES DOI' component={TextField} className={classes.TextField} />
                    <Field disabled name='mpiDOI' label='MPI DOI' component={TextField} className={classes.TextField} />
                    <Field disabled name='sroDOI' label='SRO DOI' component={TextField} className={classes.TextField} />
                  </div>
                </Paper>
                <div>
                  <Field
                    name='notes'
                    placeholder='Notes'
                    label='Notes'
                    component={TextField}
                    multiline={true}
                    rows={6}
                    variant='outlined'
                    className={classes.FullWidthTextBox}
                    fullWidth
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Field
                    name='rrcLeaseHistory'
                    placeholder='RRC Lease History'
                    label='RRC Lease History'
                    component={TextField}
                    multiline={true}
                    rows={4}
                    variant='outlined'
                    className={classes.FullWidthTextBox}
                    fullWidth
                  />
                  <Field
                    name='operatorHistory'
                    placeholder='Operator History'
                    label='Operator History'
                    component={TextField}
                    multiline={true}
                    rows={4}
                    variant='outlined'
                    className={classes.FullWidthTextBox}
                    fullWidth
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.DeleteButton}
                    onClick={(e) => setDeleteModalOpen(true)}
                  >
                    Delete Well
                  </Button>
                  <div>
                    <FormLabel style={{ display: 'block' }}>DI In Service</FormLabel>
                    <Field name='inService' component={Switch} />
                  </div>

                  <div className={classes.DIButton}>
                    <Button
                      name='di_api_modal'
                      variant='outlined'
                      color='default'
                      size='large'
                      onClick={(e) => setModalOpen(!modalOpen)}
                    >
                      Pull DI Info
                    </Button>
                  </div>
                  <DIWellModal
                    CurrentWell={Well}
                    deps={{ Operators }}
                    open={modalOpen}
                    history={history}
                    handleClose={() => setModalOpen(false)}
                    handleAgree={async (api) => {
                      const filterNullOrUndefinedFromObj = (originalObj) => {
                        return Object.getOwnPropertyNames(originalObj).reduce((obj, key) => {
                          if (originalObj[key] !== null && originalObj[key] !== undefined) {
                            obj[key] = originalObj[key];
                          }

                          return obj;
                        }, {});
                      };

                      const callSetValueOnObjProperties = (obj) => {
                        Object.getOwnPropertyNames(obj).map((key) => {
                          props.setFieldValue(key, obj[key], true);
                          props.setFieldTouched(key, true);
                        });
                      };

                      const operatedByObj = Operators.find((currentOperator) => currentOperator.name === api.operator);

                      if (api.operator && operatedByObj) {
                        api.operatedBy = operatedByObj;
                      }

                      const merged = _.extend(props.values, filterNullOrUndefinedFromObj(api));
                      callSetValueOnObjProperties(merged);
                    }}
                  />
                  <DeleteWellModal
                    open={deleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    handleAgree={() => {
                      DeleteWell({
                        variables: {
                          id: props.values.id,
                        },
                        update: (store, { data: { DeleteWell } }) => {
                          store.data.delete(DeleteWell.id);
                        },
                      })
                        .then(({ data }) => {
                          enqueueSnackbar('Successfully deleted Well!', { variant: 'success' });
                          if (data.Well && data.Well.inactive) {
                            history.push('/InactiveWells');
                          } else {
                            history.push('/Wells');
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                          enqueueSnackbar('Could not delete well!', { variant: 'error' });
                        });
                    }}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.SubmitButton}
                    onClick={(e) => {
                      WellSchema.validate(props.values, { abortEarly: false })
                        .then(() => props.submitForm())
                        .catch((errors) => {
                          console.error(errors);

                          const errorFields = errors.inner.map((err) => err.path);

                          enqueueSnackbar(`Errors On Fields: ${errorFields.join(', ')}`, {
                            variant: 'error',
                          });
                        });
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <WellMapCard
                  latitude={Well.latitude}
                  longitude={Well.longitude}
                  latitudeBottom={Well.latitudeBottom}
                  longitudeBottom={Well.longitudeBottom}
                  trueVerticalDepth={Well.trueVerticalDepth}
                  name={Well.name}
                  land={Well.landId}
                  totalDepth={Well.totalDepth}
                  upperPerf={Well.upperPerf}
                  lowerPerf={Well.lowerPerf}
                  perfLength={Well.perfLength}
                  geometry={Well.drilledOn.length !== 0 ? Well.drilledOn[0].geometry : null}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default WellDetailForm;
