import React from 'react';
import { useQuery } from '@apollo/client';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GET_INPROGRESS_WELLS } from '../../components/wells/WellQueries';

const TableStyles = makeStyles((theme) => ({
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
    marginBottom: '1rem',
  },
}));

const InProgressWells = () => {
  const classes = TableStyles();
  const { loading, error, data } = useQuery(GET_INPROGRESS_WELLS);

  // Loading state
  if (loading) {
    return <Typography>Loading wells...</Typography>;
  }

  // Error state
  if (error) {
    return <Typography>Error loading wells: {error.message}</Typography>;
  }

  // Data from query
  const wells = data?.Well || [];

  // Headers for CSV export
  const headers = [
    { label: 'Well Name', key: 'name' },
    { label: 'API #', key: 'apiNumber' },
    { label: 'RRC Lease', key: 'rrcLeaseNumber' },
    { label: 'Permit', key: 'permitNumber' },
    { label: 'Land Id', key: 'landId' },
    { label: 'Abstract', key: 'abstract' },
    { label: 'Status', key: 'status' },
    { label: 'Spud Date', key: 'spudDate' },
    { label: 'Rig Release', key: 'rigReleaseDate' },
    { label: 'Date Producing', key: 'dateProducing' },
    { label: 'Date In Pay', key: 'dateInPay' },
    { label: 'OGL', key: 'OGL' },
    { label: 'Location', key: 'location' },
    { label: 'DecProdUnit', key: 'decProdUnit' },
    { label: 'Producing Formation', key: 'producingFormation' },
    { label: 'Length of Lateral', key: 'lengthOfLateral' },
    { label: 'RRC Field', key: 'rrcField' },
    { label: 'Oil Payor', key: 'oilPayor' },
    { label: 'Gas Payor', key: 'gasPayor' },
    { label: 'Operator', key: 'operator' },
    { label: 'Family ID', key: 'familyID' },
    { label: 'MES DOI', key: 'mesDOI' },
    { label: 'MPI DOI', key: 'mpiDOI' },
    { label: 'SRO DOI', key: 'sroDOI' },
    { label: 'Comments', key: 'notes' },
    { label: 'RRC Lease Notes', key: 'rrcLeaseHistory' },
    { label: 'Operator History', key: 'operatorHistory' },
    { label: 'Unit', key: 'unit' },
  ];

  const columns = [
    { title: 'Well Name', field: 'name', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'API #', field: 'apiNumber', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'RRC Lease', field: 'rrcLeaseNumber' },
    { title: 'Permit', field: 'permitNumber' },
    {
      title: 'Land Id',
      field: 'landId',
      render: (rowData) => rowData.drilledOn.map((e) => e.id).join(', '),
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    { title: 'Abstract', field: 'abstract' },
    { title: 'Status', field: 'status' },
    { title: 'Spud Date', field: 'spudDate' },
    { title: 'Rig Release', field: 'rigReleaseDate' },
    { title: 'Date Producing', field: 'dateProducing' },
    { title: 'Date In Pay', field: 'dateInPay' },
    { title: 'OGL', field: 'OGL' },
    { title: 'Location', field: 'location' },
    { title: 'DecProdUnit', field: 'decProdUnit' },
    { title: 'Producing Formation', field: 'producingFormation' },
    { title: 'Length of Lateral', field: 'lengthOfLateral' },
    { title: 'RRC Field', field: 'rrcField' },
    { title: 'Oil Payor', field: 'oilPayor' },
    { title: 'Gas Payor', field: 'gasPayor' },
    { title: 'Operator', field: 'operator', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'Family ID', field: 'familyId' },
    {
      title: 'MES DOI',
      field: 'mesDOI',
      render: (rowData) => rowData.divisionOrder[0]?.mesDOI?.toFixed(8) || 'Incomplete',
    },
    {
      title: 'MPI DOI',
      field: 'mpiDOI',
      render: (rowData) => rowData.divisionOrder[0]?.mpiDOI?.toFixed(8) || 'Incomplete',
    },
    {
      title: 'SRO DOI',
      field: 'sroDOI',
      render: (rowData) => rowData.divisionOrder[0]?.sroDOI?.toFixed(8) || 'Incomplete',
    },
    { title: 'Comments', field: 'notes' },
    { title: 'RRC Lease Notes', field: 'rrcLeaseHistory' },
    { title: 'Operator History', field: 'operatorHistory' },
    { title: 'Unit', field: 'unit', render: (rowData) => rowData.unit?.name || '' },
  ];

  // Custom CSV export function
  const handleExportCsv = (columns, data) => {
    const exportData = wells.map((w) => ({
      name: w.name,
      apiNumber: w.apiNumber,
      rrcLeaseNumber: w.rrcLeaseNumber,
      permitNumber: w.permitNumber,
      landId: w.drilledOn.map((e) => e.id).join(', '),
      abstract: w.abstract,
      status: w.status,
      spudDate: w.spudDate,
      rigReleaseDate: w.rigReleaseDate,
      dateProducing: w.dateProducing,
      dateInPay: w.dateInPay,
      OGL: w.OGL,
      location: w.location,
      decProdUnit: w.decProdUnit,
      producingFormation: w.producingFormation,
      lengthOfLateral: w.lengthOfLateral,
      rrcField: w.rrcField,
      oilPayor: w.oilPayor,
      gasPayor: w.gasPayor,
      operator: w.operator,
      familyID: w.familyId,
      mesDOI: w.divisionOrder[0]?.mesDOI?.toFixed(8) || 'Incomplete',
      mpiDOI: w.divisionOrder[0]?.mpiDOI?.toFixed(8) || 'Incomplete',
      sroDOI: w.divisionOrder[0]?.sroDOI?.toFixed(8) || 'Incomplete',
      notes: w.notes,
      rrcLeaseHistory: w.rrcLeaseHistory,
      operatorHistory: w.operatorHistory,
      unit: w.unit?.name || '',
    }));

    // Convert to CSV string
    const headerRow = headers.map((h) => `"${h.label}"`).join(',');
    const dataRows = exportData.map((row) =>
      headers
        .map((h) => {
          const value = row[h.key] ?? ''; // Handle null/undefined values
          return `"${value.toString().replace(/"/g, '""')}"`; // Escape quotes
        })
        .join(',')
    );
    const csvContent = [headerRow, ...dataRows].join('\n');

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'InProgressWells.csv';
    link.click();
  };

  return (
    <div style={{maxWidth: '100vw'}}>
      <MaterialTable
        columns={columns}
        data={wells.map((w) => ({ ...w }))} // Create a copy to avoid immutability issues
        title=""
        components={{
          Toolbar: (props) => (
            <div
              // hidden
              style={{
                backgroundColor: '#1976d2',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'center',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          searchFieldStyle: {
            marginLeft: '-24px',
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          selection: false,
          exportButton: true, // Enable built-in export button
          exportCsv: handleExportCsv, // Custom export function
          pageSize: wells.length > 0 ? Math.min(wells.length, 10) : 10,
          pageSizeOptions: [5, 10, 20],
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
        }}
      />
    </div>
  );
};

export default InProgressWells;