import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Link from 'react-router-dom/Link';
import {Typography} from '@material-ui/core';
import moment from 'moment';

const LandROWTable = ({Land}) => {
    const columns = [
        {
            title: 'Project ID',
            field: 'id',
            render: (rowData) => <Link to={`/RightOfWays/${rowData.id}?name=${encodeURIComponent(rowData.projectName)}`}>{rowData.projectName}</Link>,
        },
        {
            title: 'Status',
            field: 'status',
        },
        {
            title: 'Lessee',
            field: 'lessee',
        },
        {
            title: 'Type',
            field: 'type',
        },
        {
            title: 'Expiration Date',
            field: 'dateOfExpiration',
            render: (rowData) => moment(rowData.dateOfExpiration).format('MM/DD/YYYY'),
        },
    ];

    return (
        <div>
            <MaterialTable
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#1976d2',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
                columns={columns}
                data={Land.rowsOn.map(r => ({...r}))}
                options={{
                    pageSize: Land.rowsOn.length,
                    search: false,
                    paging: false,
                    showTitle: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default LandROWTable;
