import React, { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Toolbar as MuiToolbar,
  Chip,
  Typography,
} from '@material-ui/core';
import LoadingView from '../../components/LoadingView';
import { CSVLink } from 'react-csv';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        color: 'white',
        '&.Mui-disabled': {
          color: 'white',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: 'white',
        '&$checked': {
          color: 'white',
        },
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
  },
});
// Custom theme

// GraphQL queries
const GET_FIELDS = gql`
    query GetFields($name: String!) {
        __type(name: $name) {
            name
            fields {
                name
                description
                type {
                    name
                    kind
                }
            }
        }
    }
`;

const GET_ROW_LANDS = gql`
    query {
        ROW {
            id
            locatedOn {
                id
            }
        }
    }
`;

// Utility functions
const alphabetize = (arrayToSort) => {
  return arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
};

const generateReportGQL = (reportName, fields) => {
  if (fields.length !== 0) {
    const queryFields = fields.map((field) => `${field.name}`).join('\n');
    return gql`
        query {
            ${reportName} {
            ${queryFields}
        }
        }
    `;
  }
  return gql`
      query {
          Well {
              id
          }
      }
  `;
};

const CustomReports = () => {
  const [getFields, { called, loading, error }] = useLazyQuery(GET_FIELDS);
  const [reportFields, setReportFields] = React.useState([]);
  const [reportName, setReportName] = React.useState('');
  const [selectedFields, setSelectedFields] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState(false);
  const [allRemoved, setAllRemoved] = React.useState(false);
  const [queryString, setQueryString] = React.useState(generateReportGQL(reportName, selectedFields));
  const [reportConfig, setReportConfig] = React.useState({ data: [{}], headers: [], filename: '' });
  const [generateReport, setGenerateReport] = React.useState(false);
  const [getReport, { data, called: reportCalled }] = useLazyQuery(queryString, { fetchPolicy: 'network-only' });
  const [getLands, { data: landData, called: landCalled }] = useLazyQuery(GET_ROW_LANDS, { fetchPolicy: 'network-only' });

  // Field management functions
  const addField = (field) => {
    setSelectedFields([...selectedFields, { ...field, key: field.description }]);
    const updatedFields = alphabetize(reportFields.filter((f) => f.name !== field.name));
    setReportFields(updatedFields);
    setGenerateReport(false);
  };

  const removeField = (field) => {
    setSelectedFields(selectedFields.filter((f) => f.description !== field.description));
    const updatedFields = alphabetize([{ ...field, key: field.description }, ...reportFields]);
    setReportFields(updatedFields);
    setGenerateReport(false);
  };

  const setAllFields = () => {
    if (allSelected) {
      setSelectedFields([]);
      setReportFields(alphabetize([...reportFields, ...selectedFields]));
    } else {
      setSelectedFields([...reportFields, ...selectedFields]);
      setReportFields([]);
    }
    setAllSelected(!allSelected);
    setAllRemoved(false);
  };

  const setAllRemovedFields = () => {
    if (allRemoved) {
      setSelectedFields([...selectedFields, ...reportFields]);
      setReportFields([]);
    } else {
      setSelectedFields([]);
      setReportFields(alphabetize([...reportFields, ...selectedFields]));
    }
    setAllRemoved(!allRemoved);
    setAllSelected(false);
    setGenerateReport(false);
  };

  // Effects
  useEffect(() => {
    const search = async () => {
      setReportFields([]);
      setSelectedFields([]);
      setAllSelected(false);
      setAllRemoved(false);
      setGenerateReport(false);
      if (reportName !== '') {
        const { data: { __type: { fields } } } = await getFields({ variables: { name: reportName } });
        const sortableFields = fields.map((field) => ({ ...field })).filter((field) => field.description);
        setReportFields(alphabetize(sortableFields));
      }
    };
    try {
      search();
    } catch (e) {
      console.log(e);
    }
  }, [reportName, getFields]);

  useEffect(() => {
    if (generateReport) getReport();
  }, [generateReport, getReport]);

  useEffect(() => {
    const csvConfig = async () => {
      let reportData = [];
      if (generateReport && data && data[reportName]) {
        if (reportName === 'ROW') {
          const { data: { ROW } } = await getLands();
          const tractIds = ROW.map((row) => row.locatedOn.map((tract) => tract.id));
          reportData = data[reportName].map((row, index) => ({
            ...row,
            landId: row.landId ? tractIds[index].join(',') : row.landId,
          }));
        } else {
          reportData = data[reportName].map((item) => ({ ...item }));
        }

        if (reportData.length > 0) {
          const csvConfig = {
            data: reportData,
            headers: selectedFields.map((field) => ({ label: field.description, key: field.name })),
            filename: `${reportName}-custom.csv`,
          };
          setReportConfig(csvConfig);
        }
      }
    };
    csvConfig();
  }, [data, generateReport, getLands, reportName]);

  // Handlers
  const handleChange = (event) => {
    setReportName(event.target.value);
    setGenerateReport(false);
  };

  if (called && loading) return <LoadingView />;
  if (error) return <p>Error :(</p>;

  return (
    <div
      style={{maxWidth: '100vw'}}
    >
      <MuiToolbar
        style={{
          backgroundColor: '#1976d2',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
          color: '#fff',
        }}
      >
        <FormControl style={{ width: 150 }}>
          <InputLabel id="demo-simple-select-label" style={{ color: '#fff' }}>
            Report Type
          </InputLabel>
          <Select
            id="demo-simple-select"
            value={reportName}
            onChange={handleChange}
            style={{ color: '#fff' }}
          >
            <MenuItem value="Well">Wells</MenuItem>
            <MenuItem value="Unit">Units</MenuItem>
            <MenuItem value="ROW">ROWs</MenuItem>
            <MenuItem value="Tract">Lands</MenuItem>
            <MenuItem value="Lease">Leases</MenuItem>
          </Select>
        </FormControl>

        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedFields.length === 0}
            onClick={() => {
              setQueryString(generateReportGQL(reportName, selectedFields));
              setGenerateReport(true);
            }}
          >
            {generateReport && !data ? 'Loading...' : 'Generate Report'}
          </Button>
          {generateReport && data && (
            <CSVLink {...reportConfig}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setGenerateReport(false)}
              >
                Download Report
              </Button>
            </CSVLink>
          )}
        </div>
      </MuiToolbar>

      <div style={{ marginTop: '10px' }}>
        <div>
          <MuiThemeProvider theme={customTheme}>
            <div
              style={{
                display: 'flex',
                maxHeight: '30px',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                background: '#1976d2',
                zIndex: 2,
              }}
            >
              <Typography
                variant="subtitle1" // Explicitly set variant
                style={{ color: 'white', marginLeft: 5 }}
              >
                REPORT FIELDS
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allSelected || reportFields.length === 0}
                    name="checkedA"
                    color="primary"
                    onClick={() => setAllFields()}
                    disabled={reportFields.length === 0}
                  />
                }
                label="Select All"
                style={{ margin: 0 }}
              />
            </div>
          </MuiThemeProvider>
          {loading ? (
            <LoadingView />
          ) : (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                padding: '10px',
                border: '1px solid black',
                minHeight: '40px',
                marginTop: '10px',
              }}
            >
              {reportFields.map((field) =>
                field.description ? (
                  <Chip
                    key={field.description}
                    label={field.description}
                    onClick={() => addField(field)}
                    color="primary"
                    variant="outlined"
                  />
                ) : null
              )}
            </div>
          )}
        </div>

        <div style={{ marginTop: '10px' }}>
          <MuiThemeProvider theme={customTheme}>
            <div
              style={{
                display: 'flex',
                maxHeight: '30px',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                background: '#1976d2',
                zIndex: 2,
              }}
            >
              <Typography
                variant="subtitle1" // Explicitly set variant
                style={{ color: 'white', marginLeft: 5 }}
              >
                SELECTED FIELDS
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allRemoved || selectedFields.length === 0}
                    name="checkedA"
                    color="secondary"
                    onClick={() => setAllRemovedFields()}
                    disabled={selectedFields.length === 0}
                  />
                }
                label="Remove All"
                style={{ margin: 0 }}
              />
            </div>
          </MuiThemeProvider>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              padding: '10px',
              border: '1px solid black',
              minHeight: '40px',
              marginTop: '10px',
            }}
          >
            {selectedFields.map((field) =>
              field.description ? (
                <Chip
                  key={field.description}
                  label={field.description}
                  onClick={() => removeField(field)}
                  color="secondary"
                  variant="outlined"
                />
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomReports;