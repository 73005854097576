import React from 'react';
import Iframe from 'react-iframe';
import MapandLegalForm from './MapandLegalForm';

const MapAndLegal = ({ Lease, onSubmit, setNotification }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <MapandLegalForm Lease={Lease} onSubmit={onSubmit} setNotification={setNotification} />
      </div>
      <div style={{ marginTop: 30 }}>
        <Iframe url={Lease.mapURL || ''} width='100%' height='100%' />
      </div>
    </div>
  );
};

export default MapAndLegal;
