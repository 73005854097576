import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import {
  TextField,
  IconButton,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  createMuiTheme,
} from '@material-ui/core';
import { Edit, Save, Add, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTextField: {
      root: {
        marginRight: 16, // Space between fields
      },
    },
    MuiInput: {
      root: {
        marginLeft: '10px',
      },
    },
    MuiInputBase: {
      root: {
        marginLeft: '10px',
      },
      input: {
        backgroundColor: 'white',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'white', // Default label color
        '&$focused': {  // Nested rule for the focused state
          color: 'white', // Color when focused
        },
      },
    },
    MuiInputLabel: {
      root: {
        marginLeft: '15px',
        color: 'gray',
        fontSize: '15px',
        zIndex: '100',
      },
      shrink: {
        color: 'white',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'white',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 16,
    padding: 8,
  },
  pillSize: {
    height: 16,
    fontSize: '0.75rem',
    '& .MuiChip-label': {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  newStateRow: {
    backgroundColor: '#1976d2',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
  },
  addCountyField: {
    backgroundColor: '#FFF',
    height: 40,
    marginRight: 8,
    marginLeft: 10,
    borderRadius: 2,
  },
}));

const DeleteItemModal = ({ open, type, itemName, onClose, onConfirm }) => (
  <Dialog open={open} onClose={() => onClose()}>
    <DialogTitle>Are you sure you want to delete this {type}?</DialogTitle>
    <DialogContent>
      <Typography variant="body1">Warning: This cannot be reversed.</Typography>
      <Typography variant="body1">{itemName}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()} color="secondary">
        Close
      </Button>
      <Button color="primary" onClick={() => { onConfirm(); onClose(); }}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

const AdminSettings = ({ data: { states = [] }, mutations: { AddState, UpdateState, DeleteState, DeleteCounty }, onCreateNewState, resetCreate }) => {
  const classes = useStyles();

  const [localStates, setLocalStates] = useState([]);
  const [editStateId, setEditStateId] = useState(null);
  const [editStateName, setEditStateName] = useState('');
  const [countyInputs, setCountyInputs] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState(null); // "county" or "state"
  const [countyToDelete, setCountyToDelete] = useState(null);
  const [stateIdForDelete, setStateIdForDelete] = useState(null);
  const [stateToDelete, setStateToDelete] = useState(null); // New for state deletion
  const [newStateName, setNewStateName] = useState('');
  const [newStateCountyInput, setNewStateCountyInput] = useState('');
  const [newStateCounties, setNewStateCounties] = useState([]);

  useEffect(() => {
    if (Array.isArray(states)) {
      const mapped = states.map(s => ({
        id: s.id,
        name: s.stateName,
        counties: s.counties ? s.counties.map(c => ({ id: c.id, name: c.name })) : [],
      }));
      setLocalStates(mapped);
    }
  }, [states]);

  // 1) Adding a brand-new state with counties
  const handleAddCountyToNewState = () => {
    if (!newStateCountyInput.trim()) return;
    setNewStateCounties(prev => [
      ...prev,
      { id: Date.now(), name: newStateCountyInput.trim() },
    ]);
    setNewStateCountyInput('');
  };

  const handleCreateNewState = () => {
    if (!newStateName.trim()) return;

    const countyNames = newStateCounties.map(c => c.name);
    AddState({ variables: { stateName: newStateName.trim(), counties: countyNames } })
      .then(res => {
        const newId = res.data.addState.id;
        const actualName = res.data.addState.stateName;
        const actualCounties = res.data.addState.counties || [];
        setLocalStates(prev => [
          ...prev,
          { id: newId, name: actualName, counties: actualCounties },
        ]);
        setNewStateName('');
        setNewStateCounties([]);
        setNewStateCountyInput('');
        if (resetCreate) resetCreate();
      })
      .catch(err => {
        console.error('Error creating new state:', err.message);
        if (resetCreate) resetCreate(); // Reset even on error, if desired
      });
  };

  // 2) Editing a state's name
  const handleStartEditState = (stateId, currentName) => {
    setEditStateId(stateId);
    setEditStateName(currentName);
  };

  const handleSaveEditState = (stateId) => {
    const theState = localStates.find(s => s.id === stateId);
    if (!theState) return;

    const newName = editStateName.trim();
    const countyNames = theState.counties.map(c => c.name);
    UpdateState({ variables: { id: stateId, stateName: newName, counties: countyNames } })
      .then(() => {
        setLocalStates(prev => prev.map(s => (s.id === stateId ? { ...s, name: newName } : s)));
        setEditStateId(null);
        setEditStateName('');
      })
      .catch(err => console.error('Error updating state name:', err.message));
  };

  // 3) Adding a county to an existing state
  const handleCountyInputChange = (stateId, value) => {
    setCountyInputs(prev => ({ ...prev, [stateId]: value }));
  };

  const handleAddCountyToExistingState = (stateId) => {
    const inputVal = countyInputs[stateId]?.trim();
    if (!inputVal) return;

    setLocalStates(prev => prev.map(s => {
      if (s.id === stateId) {
        return {
          ...s,
          counties: [...s.counties, { id: Date.now(), name: inputVal }],
        };
      }
      return s;
    }));

    const theState = localStates.find(s => s.id === stateId);
    if (theState) {
      const newCountyNames = [...theState.counties.map(c => c.name), inputVal];
      UpdateState({
        variables: { id: stateId, stateName: theState.name, counties: newCountyNames },
      }).catch(err => console.error('Error adding county:', err.message));
    }

    setCountyInputs(prev => ({ ...prev, [stateId]: '' }));
  };

  // 4) Deleting a county
  const handleRequestDeleteCounty = (stateId, county) => {
    setDeleteModalOpen(true);
    setDeleteType('county');
    setCountyToDelete(county.id);
    setStateIdForDelete(stateId);
    setStateToDelete(null); // Clear state-specific data
  };

  // 5) Deleting a state
  const handleRequestDeleteState = (stateId) => {
    const state = localStates.find(s => s.id === stateId);
    if (!state) return;
    setDeleteModalOpen(true);
    setDeleteType('state');
    setStateToDelete(stateId);
    setCountyToDelete(null); // Clear county-specific data
    setStateIdForDelete(null);
  };

  const handleConfirmDelete = () => {
    if (deleteType === 'county' && countyToDelete) {
      DeleteCounty({ variables: { id: countyToDelete } });
      setLocalStates(prev => prev.map(s => {
        if (s.id === stateIdForDelete) {
          return {
            ...s,
            counties: s.counties.filter(c => c.id !== countyToDelete),
          };
        }
        return s;
      }));
      const theState = localStates.find(s => s.id === stateIdForDelete);
      if (theState) {
        const newCountyNames = theState.counties
          .filter(c => c.id !== countyToDelete)
          .map(c => c.name);
        UpdateState({
          variables: { id: stateIdForDelete, stateName: theState.name, counties: newCountyNames },
        }).catch(err => console.error('Error removing county:', err.message));
      }
    } else if (deleteType === 'state' && stateToDelete) {
      DeleteState({ variables: { id: stateToDelete } })
        .then(res => {
          if (res.data.deleteStateByIdentity) {
            setLocalStates(prev => prev.filter(s => s.id !== stateToDelete));
          } else {
            console.log('Could not delete state, DB returned false');
          }
        })
        .catch(err => console.error('Error deleting state:', err.message));
    }
    setCountyToDelete(null);
    setStateToDelete(null);
    setStateIdForDelete(null);
    setDeleteType(null);
    setDeleteModalOpen(false); // Ensure modal closes after confirmation
  };

  useEffect(() => {
    if (onCreateNewState === 'True') {
      handleCreateNewState();
    }
  }, [onCreateNewState]);

  const columns = [
    {
      title: 'State',
      field: 'name',
      render: rowData => {
        const isEditing = editStateId === rowData.id;
        return isEditing ? (
          <TextField
            value={editStateName}
            onChange={e => setEditStateName(e.target.value)}
          />
        ) : (
          rowData.name
        );
      },
    },
    {
      title: 'Counties',
      field: 'counties',
      editable: 'never',
      render: rowData => (
        <div>
          {(rowData.counties || []).map(county => (
            <Chip
              key={county.id}
              label={county.name}
              onDelete={() => handleRequestDeleteCounty(rowData.id, county)}
              deleteIcon={<Delete />}
              color="default"
              className={classes.pillSize}
            />
          ))}
        </div>
      ),
    },
    {
      title: 'Add County',
      field: 'addCounty',
      editable: 'never',
      render: rowData => (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
          <div style={{ height: '30px' }}>
            <TextField
              size="small"
              placeholder="County Name"
              value={countyInputs[rowData.id] || ''}
              onChange={e => handleCountyInputChange(rowData.id, e.target.value)}
              className={classes.addCountyField}
              style={{ height: '100%' }}
            />
            <IconButton
              color="primary"
              onClick={() => handleAddCountyToExistingState(rowData.id)}
            >
              <Add />
            </IconButton>
          </div>
        </div>
      ),
    },
    {
      title: 'Actions',
      field: 'actions',
      editable: 'never',
      render: rowData => {
        const isEditing = editStateId === rowData.id;
        return (
          <>
            {isEditing ? (
              <IconButton
                color="primary"
                onClick={() => handleSaveEditState(rowData.id)}
              >
                <Save />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={() => handleStartEditState(rowData.id, rowData.name)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              color="secondary"
              onClick={() => handleRequestDeleteState(rowData.id)}
              style={{ marginLeft: 8 }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ backgroundColor: '#1976d2', marginTop: '-20px' }}>
        <MuiThemeProvider theme={theme}>
          <TextField
            label="State Name"
            value={newStateName}
            onChange={e => setNewStateName(e.target.value)}
          />
          {newStateCounties.map(county => (
            <Chip
              key={county.id}
              label={county.name}
              style={{ marginTop: '15px', marginLeft: '5px' }}
              onDelete={() => setNewStateCounties(prev => prev.filter(c => c.id !== county.id))}
              deleteIcon={<Delete color="primary" />}
              color="primary"
            />
          ))}
          <TextField
            label="County +"
            value={newStateCountyInput}
            onChange={e => setNewStateCountyInput(e.target.value)}
          />
          <IconButton color="secondary" onClick={handleAddCountyToNewState}>
            <Add />
          </IconButton>
        </MuiThemeProvider>
        <Button
          style={{marginTop: '10px'}}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleCreateNewState}
        >
          Create
        </Button>

      <MaterialTable
        title=""
        columns={columns}
        data={localStates.map(s => ({ ...s, counties: s.counties || [] }))}
        options={{
          paging: false,
          search: false,
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          actionsColumnIndex: -1,
        }}
        components={{
          Toolbar: () => null, // Remove default toolbar to avoid add button
        }}
        editable={{
          onRowAdd: null,
          onRowUpdate: null,
          onRowDelete: null,
        }}
      />

      <DeleteItemModal
        open={deleteModalOpen}
        type={deleteType || 'item'}
        itemName={
          deleteType === 'county'
            ? localStates.flatMap(s => s.counties).find(c => c.id === countyToDelete)?.name || ''
            : deleteType === 'state'
              ? localStates.find(s => s.id === stateToDelete)?.name || ''
              : ''
        }
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default AdminSettings;