import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Auth from '../../util/Auth';
import { LeaseSchema } from '../../util/schema/LeaseSchema';
import { Button } from '@material-ui/core';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const TableStyles = makeStyles((theme) => ({
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '10px',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const MapandLegalForm = ({ Lease, onSubmit, setNotification }) => {
  const classes = TableStyles();

  return (
    <Formik
      initialValues={{
        ...Lease,
        mapURL: Lease.mapURL ?? '',
        legalDescription: Lease.legalDescription ?? '',
      }}
      enableReinitialize={true} // Ensure form updates if Lease prop changes
      onSubmit={(values, actions) => {
        LeaseSchema.validate(values, { stripUnknown: true })
          .then((validLease) => onSubmit(validLease))
          .then(() => {
            actions.setSubmitting(false);
            setNotification('URL Successfully Entered', { variant: 'success' });
          })
          .catch((err) => {
            actions.setSubmitting(false);
            console.error(err);
            setNotification('Could not enter URL!', { variant: 'error' });
          });
      }}
    >
      {(props) => (
        <Form className={classes.form}>
          <Field
            name='mapURL'
            placeholder='Image URL'
            label='Image URL'
            component={TextField}
            className={classes.TextField}
            fullWidth
          />
          <Field
            name='legalDescription'
            placeholder='Legal Description'
            label='Legal Description'
            component={TextField}
            style={{ float: 'right' }}
            multiline={true}
            rows={2}
            variant='outlined'
            className={classes.FullWidthTextBox}
            fullWidth
          />
          <div>
            <Button variant='contained' color='primary' type='submit' style={{ margin: 20 }}>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MapandLegalForm;