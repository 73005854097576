import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import {Typography} from '@material-ui/core';
import Link from 'react-router-dom/Link';

const LandWellsTable = ({Land}) => {
    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: (rowData) => <Link to={`/Wells/${rowData.id}?name=${encodeURIComponent(rowData.name)}`}>{rowData.name}</Link>,
        },
        {
            title: 'Land ID',
            field: 'drilledOn',
            render: (rowData) => rowData.drilledOn.map((w) => w.id).join(', '),
        },
        {
            title: 'Status',
            field: 'status',
        }
    ];

    return (
        <div>
            <MaterialTable
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#1976d2',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
                columns={columns}
                data={Land.drilledOn.map(d => ({...d}))}
                options={{
                    search: false,
                    showTitle: false,
                    paging: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default LandWellsTable;
