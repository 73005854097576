import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import WellMaster from './WellMaster';
import InactiveWells from './InactiveWells';
import InProgressWells from './InProgressWells';
import RightOfWay from './RightOfWay';
import CustomReports from "./CustomReports";

const Reports = () => {
  function TabContainer(props) {
    return (
      <Typography component='div'>
        {props.children}
      </Typography>
    );
  }

  const [value, setValue] = React.useState(0);

  // Custom styles for tabs
  const tabStyles = {
    minWidth: '60px',
    padding: '1px 1px',
  };

  return (
    <>
      <AppBar position='static'>
        <Tabs
          variant="scrollable"
          scrollButtons="on"
          value={value}
          onChange={(e, newVal) => setValue(newVal)}
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'flex-start', // Align tabs to start
            },
            '& .MuiTabs-scroller': {
              overflowX: 'auto !important', // Ensure scrolling works
            }
          }}
        >
          <Tab label='Wells' style={tabStyles} />
          <Tab label='Inactive Wells' style={tabStyles} />
          <Tab label='InProgress Wells' style={tabStyles} />
          <Tab label='ROWs' style={tabStyles} />
          <Tab label='Production' style={tabStyles} />
          <Tab label='Leases' style={tabStyles} />
          <Tab label='Lands' style={tabStyles} />
          <Tab label='Operators' style={tabStyles} />
          <Tab label='Custom' style={tabStyles} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <WellMaster />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <InactiveWells />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <InProgressWells />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer>
          <RightOfWay />
        </TabContainer>
      )}
      {value === 4 && <TabContainer>Production</TabContainer>}
      {value === 5 && <TabContainer>Leases</TabContainer>}
      {value === 6 && <TabContainer>Lands</TabContainer>}
      {value === 7 && <TabContainer>Operators</TabContainer>}
      {value === 8 && <TabContainer><CustomReports /></TabContainer>}
    </>
  );
};

export default Reports;