import React, { useRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import {TextField} from '@material-ui/core';
import moment from 'moment';
import {FieldArray, Form, Formik} from 'formik';
import LoadingView from "../../components/LoadingView";
import FileUploadModal from "../../components/files/FileUploadModal";
import {FILE_QUERY} from '../../components/files/fileQueries';
import {useLazyQuery, useMutation} from "@apollo/client";
import {EDIT_TITLE_DOCUMENT} from "../../components/tracts/TractQueries";
// import { getPageNumberFromQueryParams } from '../../util/Utils';
// import queryString from 'query-string';


// const calculatedPageSize = Math.max(
//   1,
//   Math.floor((window.innerHeight - 300) / 40)
// );

const ChainOfTitleTable = ({Tract, queries, match}) => {
    const tractID = match.params.tractID;
    const [fileKey, setFileKey] = React.useState(null);
    const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
    const [rowInfo, setRowInfo] = React.useState({});
    const [loadFile] = useLazyQuery(FILE_QUERY,
        {variables: {fileName: fileKey}});
    const {createTitle, deleteTitle, editTitle} = queries;
    const [updateTitle] = useMutation(EDIT_TITLE_DOCUMENT);
  // const [pageNumber, setPageNumber] = useState(
  //   getPageNumberFromQueryParams(queryString.parse(window.location.search))
  // );
  const tableRef = useRef(null);

    // const [loading, setLoading] = React.useState(false);

    // if (loading) {
    //     return <LoadingView/>
    // }

    const formikData = {
        finalData: Tract.titleRun
    }

    return (
        <div>
          {/*Keep this here as a reminder of where it used to be and just in case they ever want it back*/}
            {/*<Uploader*/}
            {/*    itemID={Tract.id}*/}
            {/*    updateFunction={createTitle}*/}
            {/*    typeOfUploader={"TRACT"}*/}
            {/*    history={history}*/}
            {/*    setValue={setValue}*/}
            {/*    setLoading={setLoading}*/}
            {/*/>*/}
            <Formik onSubmit={() => {
            }} initialValues={formikData}>
                {(props) => (
                    <Form>
                        <FieldArray name='finalData'>
                            {({push, remove, insert}) => {
                              const pageLength = props.values.finalData.length
                              const commonWidth = '15%'
                              const commonHeight = '30px'
                                const columns = [
                                    {
                                        title: 'Vol/Page',
                                        field: 'volPage',
                                        cellStyle: {
                                          width: commonWidth,
                                          height: commonHeight,
                                          overflow: 'auto'
                                        },
                                    },
                                    {
                                        title: 'Book',
                                        field: 'book',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'Effective Date',
                                        field: 'effectiveDate',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                      render: (rowData) => moment(rowData.effectiveDate, 'MM/DD/YYYY').isValid()
                                        ? moment(rowData.effectiveDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
                                        : 'Invalid Date',
                                      customSort: (a, b) => (
                                        moment(a.effectiveDate, 'MM/DD/YYYY').isAfter(moment(b.effectiveDate, 'MM/DD/YYYY')) ? 1 : -1
                                      ),
                                      editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                    },
                                    {
                                        title: 'Date Filed',
                                        field: 'dateFiled',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                      render: (rowData) => moment(rowData.dateFiled, 'MM/DD/YYYY').isValid()
                                        ? moment(rowData.dateFiled, 'MM/DD/YYYY').format('MM/DD/YYYY')
                                        : 'Invalid Date',
                                      customSort: (a, b) => (
                                        moment(a.dateFiled, 'MM/DD/YYYY').isAfter(moment(b.dateFiled, 'MM/DD/YYYY')) ? 1 : -1
                                      ),
                                      editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                    },
                                    {
                                        title: 'Doc Type',
                                        field: 'docType',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'Grantor',
                                        field: 'grantor',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                        editable: 'onAdd',
                                    },
                                    {
                                        title: 'Grantee',
                                        field: 'grantee',
                                        editable: 'onAdd',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'NMA Total',
                                        field: 'nmaTotal',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'NSA Total',
                                        field: 'nsaTotal',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'Land Description',
                                        field: 'landDescription',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                    {
                                        title: 'Comments',
                                        field: 'comments',
                                      cellStyle: {
                                        width: commonWidth,
                                        height: commonHeight,
                                        overflow: 'auto'
                                      },
                                    },
                                ];

                                return (
                                  <div>
                                    <MaterialTable
                                      // Keep this here for later when we want to move search and export to the nav bar like we did with the add/plus button
                                      components={{
                                        Toolbar: (props) => (
                                          <div
                                            // hidden
                                            style={{
                                              backgroundColor: '#1976d2',
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <MTableToolbar {...props} />
                                          </div>
                                        ),
                                      }}
                                      columns={columns}
                                      data={props.values.finalData.map(t => ({...t}))}
                                      icons={{
                                        Add: props => (
                                          <div id="myChain" ref={tableRef} hidden>
                                          </div>
                                        ),
                                      }}
                                      // onChangePage={(page) => setPageNumber(page)}
                                      options={{
                                        pageSize: pageLength,
                                        maxBodyHeight: 'calc(100vh - 150px)',
                                        initialPage: 0,
                                        searchFieldAlignment: 'left',
                                        searchFieldStyle: {
                                          backgroundColor: '#fff',
                                          color: '#000',
                                          border: 'none',
                                          paddingTop: '4px',
                                          paddingBottom: '4px',
                                          paddingLeft: '8px',
                                          paddingRight: '8px',
                                          borderRadius: '4px',
                                        },
                                        showTitle: false,
                                        toolbarButtonAlignment: 'left',
                                        addRowPosition: 'first',
                                        exportButton: true,
                                        headerStyle: {
                                          backgroundColor: '#1976d2',
                                          color: '#FFF',
                                          textAlign: 'left',
                                          justifyContent: 'center',
                                          whiteSpace: 'pre',
                                          // position: 'fixed',
                                        },
                                      }}
                                      editable={{
                                        onRowAdd: (newData) => {
                                          newData.tractID = Tract.id;
                                          newData.book = !newData.book ? 'N/A' : newData.book;
                                          newData.comments = !newData.comments ? 'None' : newData.comments;
                                          newData.nsaTotal = !newData.nsaTotal ? '0' : newData.nsaTotal;
                                          newData.nmaTotal = !newData.nmaTotal ? '0' : newData.nmaTotal;
                                          return createTitle(newData).then(({data: {createTitleDocument}}) => {
                                            push(createTitleDocument.titleRun[0]);
                                          });
                                        },
                                        onRowUpdate: (newData, oldData) => {
                                          return editTitle(newData).then(({data: {UpdateLandTitle}}) => {
                                            remove(oldData.tableData.id);
                                            insert(oldData.tableData.id, UpdateLandTitle);
                                          });
                                        },
                                        onRowDelete: (oldData) => {
                                          return deleteTitle({id: oldData.id}).then((data) => {
                                            remove(oldData.tableData.id);
                                          });
                                        },
                                      }}
                                      actions={
                                        [
                                          {
                                            icon: 'addFile',
                                            tooltip: 'Add File',
                                            onClick: (event, rowData) => {
                                              // Removed tableData param from onClick unused
                                              try {
                                                const {id} = rowData;
                                                const fileName = `${tractID}/${id}`;
                                                setFileKey(fileName);
                                                setRowInfo(rowData);
                                                setFileUploadOpen(!fileUploadOpen);

                                              } catch (e) {
                                                console.log(e);
                                              }
                                            }
                                          },
                                          {
                                            icon: 'map',
                                            tooltip: 'View File',
                                            onClick: async (event, rowData) => {
                                              const {id} = rowData;
                                              const fileName = `${tractID}/${id}`;
                                              setFileKey(rowData.fileName ? rowData.fileName : fileName);
                                              try {
                                                const response = await loadFile();
                                                const {data: {getFile}} = response;
                                                const url = getFile[0].requestUrl;
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.click();
                                              } catch (e) {
                                                console.log(e);
                                              }
                                            }
                                          }
                                        ]
                                      }
                                      localization={{
                                        header: {
                                          actions: 'Actions'},
                                        body: {
                                          addTooltip: 'Add row',
                                          editTooltip: 'Edit row',
                                          deleteTooltip: 'Delete row',
                                          cancelTooltip: 'Cancel',
                                          saveTooltip: 'Save',
                                        },
                                      }}
                                    />

                                  </div>

                                );
                            }}
                        </FieldArray>
                        <FileUploadModal
                            open={fileUploadOpen}
                            setFileUploadOpen={setFileUploadOpen}
                            recordUpdate={updateTitle}
                            fileKey={fileKey}
                            rowInfo={rowInfo}
                            values={props.values}
                            setFieldValue={props.setFieldValue}
                            uploadType={"TITLE"}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ChainOfTitleTable;
