import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
const boxShadow = '0px 5px 10px 2px rgba(0, 0, 0, 0.35), ' +
  '0px 8px 8px 0px rgba(0, 0, 0, 0.3), ' +
  '0px 10px 6px -6px rgba(0, 0, 0, 0.28)';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue['700'],
      light: blue['300'],
      dark: blue['900'],
    },
    secondary: {
      main: red['700'],
      light: red['300'],
      dark: red['900'],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiAppBar: {
      root: {
        '& .MuiToolbar-root': {
          minHeight: '0px', // Adjust as needed
        }
      }
    },
    MuiTableCell: {
      root: {
        // Base styling for all <TableCell>
        padding: '0px 0px 0px 20px',
        borderBottom: '1px solid #ccc',
      },
      head: {
        // Specifically for header cells (th)
        // fontWeight: 'bold',
        fontSize: '.8rem',
        // backgroundColor: '#1976d2',
        color: '#fff',
        height: '1px',
      },
      body: {
        // Specifically for body cells (td)
        fontSize: '0.8rem',
        color: '#333',
        overflowY: 'auto',
        overflowX: 'auto',
      },
      // If we want "padding='checkbox'" or "size='small'" variants,
      paddingCheckbox: {
        // The cell containing checkboxes
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTable: {
      root: {
        padding: '0px 0px',
        backgroundColor: 'transparent',
      }
    },
    MuiTableRow: {
      head: {
        position: 'sticky',
        padding: '0px 0px 0px 20px',
        zIndex: 1,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        height: '1px',
        minHeight: '1px',
      },
    },
    MuiButton: {
      root: {
        boxShadow,
      },
      contained: {
        boxShadow,
      },
    },
  },
});

function ThemeProvider(props) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

export default ThemeProvider;
