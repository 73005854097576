import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

const OGLTable = ({Tract}) => {
    const columns = [
        {
            title: 'Lease',
            field: 'Lease.leaseName',
            render: (rowData) => {
                return (
                    <Link to={`/Leases/${rowData.Lease.id}?name=${encodeURIComponent(rowData.Lease.leaseName)}`} target={'_blank'}>
                        {rowData.Lease.leaseName}
                    </Link>
                );
            },
        },
        {
            title: 'Status',
            field: 'Lease.status',
        },
        {
            title: 'NSA',
            field: 'netSurfaceAcres',
        },
        {
            title: 'NMA',
            field: 'netMineralAcres',
        },
        {
            title: 'Lessee',
            field: 'Lease.currentLessee',
        },
    ];

    const data = Tract.interest;

    return (
        <div>
            <MaterialTable
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#1976d2',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
                columns={columns}
                data={data.map(c => ({...c}))}
                options={{
                    search: false,
                    showTitle: false,
                    paging: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default OGLTable;
