import React, { useRef} from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button } from '@material-ui/core';
import Auth from '../../util/Auth';
import PERMISSIONS_LIST from '../../util/RolesEnum';
// Removed table pagination functionality currently commented out
// import queryString from 'query-string';
// import { getPageNumberFromQueryParams } from '../../util/Utils';


// function replaceHistoryWithPage(pageNum, { history }) {
//   history.replace(`/Operators?page=${pageNum}`);
// }
const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

const OperatorTable = ({ data, history }) => {
  // const [pageNumber, setPageNumber] = useState(
  //   getPageNumberFromQueryParams(queryString.parse(window.location.search))
  // );

  const tableRef = useRef(null);

  // Make sure pageSize is at least 1
  // const calculatedPageSize = Math.max(
  //   1,
  //   Math.floor((window.innerHeight - 300) / 40)
  // );
  //
  // useEffect(() => {
  //   if (tableRef.current?.dataManager) {
  //     tableRef.current.dataManager.changePageSize(calculatedPageSize);
  //   }
  // }, [calculatedPageSize]);
  const columns = [
    {
      title: 'CONTACT NAME',
      field: 'name',
      render: (rowData) => (
        <Link
          to={`/Operators/${rowData.id}?name=${encodeURIComponent(rowData.name)}`}
          target='_blank'
          // onClick={() => replaceHistoryWithPage(pageNumber, { history })}
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      title: 'TYPE',
      field: 'type',
    },
    {
      title: 'COMPANY WEBSITE',
      field: 'companyWebsite',
      render: (rowData) => (
        <Link to={`${rowData.companyWebsite}`} target='_blank' rel='noopener noreferrer'>
          {rowData.companyWebsite}
        </Link>
      ),
    },
    {
      title: 'OPERATOR NUMBER',
      field: 'operatorNumber',
    },
    {
      title: 'MAIN PHONE',
      field: 'phone',
      render: (rowData) => (rowData.phone ? rowData.phone : 'N/A'),
    },
    {
      title: 'MES #',
      field: 'mesAccountNumber',
      render: (rowData) => (rowData.mesAccountNumber ? rowData.mesAccountNumber : 'N/A'),
    },
    {
      title: 'MPI #',
      field: 'mpiAccountNumber',
      render: (rowData) => (rowData.mpiAccountNumber ? rowData.mpiAccountNumber : 'N/A'),
    }
  ];
  return (
      <div>
        <MaterialTable
          tableRef={tableRef}
          data={data}
          columns={columns}
          title=''
          // onChangePage={(page) => setPageNumber(page)}
          options={{
            exportButton: true,
            tableLayout: 'auto',
            paging: false,
            search: true,
            // initialPage: pageNumber,
            // pageSize: calculatedPageSize,
            searchFieldStyle: {
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: '#fff',
              color: '#000',
            },
            headerStyle: {
              backgroundColor: '#1976d2',
              color: '#FFF',
              textAlign: 'left',
              justifyContent: 'center',
              whiteSpace: 'pre',
            },
            searchFieldAlignment: 'left',
            toolbarButtonAlignment: 'left',
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: '#1976d2',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 16px',
                }}
              >
                <MTableToolbar {...props} />
                {hasPermissions && (
                  <Button
                    onClick={() => history.push('/addOperator')}
                    variant="contained"
                    style={{
                      fontSize: '0.75rem',
                      minWidth: 'auto',
                      backgroundColor: '#1976d2',
                      color: 'white',
                      padding: '4px 10px',
                      marginRight: '40px',
                    }}
                  >
                    Add Contact
                  </Button>
                )}
              </div>
            ),
          }}
        />
      </div>
  );
};


export default OperatorTable;