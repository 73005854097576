import React from 'react';
import { ADD_TRACT, DefaultTract, TRACTS_QUERY } from '../../components/tracts/TractQueries';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik } from 'formik';
import { TractSchema } from '../../components/tracts/TractSchema';
import { TextField } from 'formik-material-ui';
import { Button, MenuItem, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMutation, useQuery } from '@apollo/client';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import math from 'mathjs';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { STATE_LIST } from '../../components/admin/adminQueries';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const EditFormStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  rowItem: {
    '& + &': {
      marginLeft: 20,
    },
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const propertyValues = (fraction) => (gross) => {
  const decimal = math.number(math.fraction(fraction));
  return {
    net: math.multiply(decimal, gross),
    decimal: math.multiply(decimal, 100),
  };
};

const AddTract = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = EditFormStyles();
  const StateListQuery = useQuery(STATE_LIST);
  const [addTract] = useMutation(ADD_TRACT);
  // Wait for query to complete before rendering the form

  const StateCounties =  StateListQuery.data
  console.log(StateCounties, 'STATE COUNTIES HERE')
  console.log(StateListQuery, "STRAIGHT UP QUERY DATA")
  let counties = {}
  if (StateCounties?.states) StateCounties.states.forEach(state => {counties[state.stateName] = state.counties})

  if (StateListQuery.loading) {
    return <Typography>Loading states...</Typography>;
  }
  if (StateListQuery.error) {
    return <Typography>Error loading states: {StateListQuery.error.message}</Typography>;
  }
  const getStateDependentFields = (state) => {
    if (state === 'Texas') {
      return ['blk', 'section', 'tract', 'lot', 'survey'];
    } else {
      return ['range', 'township', 'section', 'tract', 'lot'];
    }
  };

  return (
    <>
      <Formik
        initialValues={{...DefaultTract,
          state: '',
          county: '',
          blk: '',
          section: '',
          tract: '',
          lot: '',
          survey: '',
          range: '',
          township: '',
          purchaser: '',
          docType: '',
          recording: ''
        }}
        validationSchema={TractSchema}
        onSubmit={(values, actions) => {
          const numericFields = [
            'grossMineralAcres',
            'grossSurfaceAcres',
            'calicheAcres',
            'bonusDelayAcres',
            'otherMineralAcres',
            'mineralClassifiedAcres'
          ];

          numericFields.forEach(field => {
            values[field] = values[field] === '' ? 0 : parseFloat(values[field]);
          });

          // 2. Then process ownership values
          values.id = values.name;
          values.mineralOwnershipPercentage = propertyValues(values.mineralFraction)(
            values.grossMineralAcres
          ).decimal.toFixed(8);
          values.surfaceOwnershipPercentage = propertyValues(values.surfaceFraction)(
            values.grossSurfaceAcres
          ).decimal.toFixed(8);
          values.familyNMA = propertyValues(values.mineralFraction)(values.grossMineralAcres).net.toFixed(8);
          values.familyNSA = propertyValues(values.surfaceFraction)(values.grossSurfaceAcres).net.toFixed(8);
          values.mineralOwnership = values.familyNMA !== 0;
          values.surfaceOwnership = values.familyNSA !== 0;

          actions.setSubmitting(true);

          return addTract({
            variables: { ...values },
            update: (store, { data: { CreateTract } }) => {
              try {
                const AllTracts = store.readQuery({ query: TRACTS_QUERY });

                const data = { Tract: [...AllTracts.Tract, CreateTract] };

                store.writeQuery({ query: TRACTS_QUERY, data: data });
              } catch (e) {
                console.log('Could not add lease to all lands query due to it not being run yet');
              }
            },
          })
            .then(() => {
              enqueueSnackbar('Successfully Added Land!', { variant: 'success' });
              actions.setSubmitting(false);
              history.push(`/Lands/${values.id}`);
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(error.toString(), { variant: 'error' });
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div style={{ display: 'flex', direction: 'row' }}>
              <Field
                name='name'
                placeholder='Land ID'
                component={TextField}
                label='Land ID'
                className={classes.TextField}
              />
              <Field
                name='familyID'
                placeholder='Family ID'
                component={TextField}
                label='Family ID'
                className={classes.TextField}
              />
              <Field
                name='abstract'
                placeholder='Abstract'
                component={TextField}
                label='Abstract'
                className={classes.TextField}
              />
              <Field
                name='description'
                placeholder='Description'
                component={TextField}
                label='Description'
                className={classes.TextField}
              />

              <Field
                name="state"
                select
                component={TextField}
                label="State *"
                className={classes.TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ width: 200 }}
                value={props.values.state || ''} // Ensure controlled value
                SelectProps={{
                  native: false,
                  onChange: (e) => {
                    const newState = e.target.value;
                    const stateDependentFields = getStateDependentFields(newState);
                    stateDependentFields.forEach((field) => {
                      props.setFieldValue(field, '');
                    });
                    props.setFieldValue('state', newState);
                    props.setFieldValue('county', '');
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select State
                </MenuItem>
                {StateCounties?.states?.map((state) => (
                  <MenuItem key={state.id} value={state.stateName}>
                    {state.stateName}
                  </MenuItem>
                ))}
              </Field>

              <Field
                name="county"
                select
                component={TextField}
                label="County *"
                className={classes.TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ width: 200 }}
                value={props.values.county || ''}
                disabled={!props.values.state || !counties[props.values.state]?.length}
                SelectProps={{
                  native: false,
                  onChange: (e) => {
                    props.setFieldValue('county', e.target.value);
                  }
                }}
              >
                <MenuItem value="" disabled>
                  {props.values.state
                    ? (counties[props.values.state]?.length ? 'Select County' : 'No counties available')
                    : 'Select a state first'}
                </MenuItem>
                {counties[props.values.state]?.map(county => (
                  <MenuItem key={county.id} value={county.name}>
                    {county.name}
                  </MenuItem>
                ))}
              </Field>

            </div>

            <div style={{ display: 'flex' }}>
              <Field
                name='purchaser'
                placeholder='Purchaser'
                component={TextField}
                label='Purchaser'
                className={classes.TextField}
              />
              <Field
                name='docType'
                placeholder='Document Type'
                component={TextField}
                label='Document Type'
                className={classes.TextField}
              />
              <Field
                name='recording'
                placeholder='Recording'
                component={TextField}
                label='Recording'
                className={classes.TextField}
              />
              <Field
                name='purchaseDate'
                label='Purchase Date'
                placeholder='MM-DD-YYYY'
                type='date'
                component={TextField}
                className={classes.TextField}
                margin={'normal'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <Field
                name='color'
                select
                label='Color Code'
                component={TextField}
                className={classes.TextField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 200 }}
              >
                <MenuItem value={'M'}>Minerals</MenuItem>
                <MenuItem value={'M&S'}>Minerals & Surface</MenuItem>
                <MenuItem value={'S'}>Surface</MenuItem>
              </Field>
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Paper className={classes.paper} style={{ display: props.values.state === 'None' ? 'none' : 'block' }}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Typography variant='h5' align='center' className={classes.title}>
                    {props.values.state === 'Texas' ? 'Texas' : 'Cats & Dogs'}
                  </Typography>
                  {props.values.state === 'Texas' ? (
                    <>
                      <Field
                        name="blk"
                        placeholder="TX Block"
                        component={TextField}
                        label="TX Block"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="section"
                        placeholder="TX Section"
                        component={TextField}
                        label="TX Section"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="tract"
                        placeholder="TX Tract"
                        component={TextField}
                        label="TX Tract"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="lot"
                        placeholder="TX Lot"
                        component={TextField}
                        label="TX Lot"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="survey"
                        placeholder="Survey"
                        component={TextField}
                        label="Survey"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                    </>
                  ) : (
                    <>
                      <Field
                        name="range"
                        placeholder="Range"
                        component={TextField}
                        label="Range"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="township"
                        placeholder="Township"
                        component={TextField}
                        label="Township"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="section"
                        placeholder="Section"
                        component={TextField}
                        label="Section"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="tract"
                        placeholder="Tract"
                        component={TextField}
                        label="Tract"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        name="lot"
                        placeholder="Lot"
                        component={TextField}
                        label="Lot"
                        className={classes.TextField}
                        InputLabelProps={{ shrink: true }}
                      />
                    </>
                  )}
                </div>
              </Paper>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant='h5' align='center' className={classes.title}>
                    Ownership
                  </Typography>
                  <div style={{ display: 'Flex', alignContent: 'space-around' }}>
                    <div>
                      <Typography variant='h5' align='center' className={classes.title}>
                        Minerals
                      </Typography>
                      <Field
                        name='grossMineralAcres'
                        placeholder='GMA'
                        component={TextField}
                        label='GMA'
                        className={classes.TextField}
                      />
                      <Field
                        name='mineralFraction'
                        placeholder='Mineral Fraction'
                        component={TextField}
                        label='Mineral Fraction'
                        className={classes.TextField}
                      />
                      <Field
                        name='mineralOwnershipPercentage'
                        placeholder='MIN %'
                        label='MIN %'
                        component={TextField}
                        InputProps={{
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                        className={classes.TextField}
                        disabled
                      />
                      <Field
                        name='familyNMA'
                        placeholder='NMA'
                        component={TextField}
                        label='NMA'
                        className={classes.TextField}
                        disabled
                      />
                    </div>
                    <div>
                      <Typography variant='h5' align='center' className={classes.title}>
                        Surface
                      </Typography>
                      <Field
                        name='grossSurfaceAcres'
                        placeholder='GSA'
                        component={TextField}
                        label='GSA'
                        className={classes.TextField}
                      />
                      <Field
                        name='surfaceFraction'
                        placeholder='Surface Fraction'
                        component={TextField}
                        label='Surface Fraction'
                        className={classes.TextField}
                      />
                      <Field
                        name='surfaceOwnershipPercentage'
                        placeholder='SRF %'
                        label='SRF %'
                        component={TextField}
                        InputProps={{
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                        className={classes.TextField}
                        disabled
                      />
                      <Field
                        name='familyNSA'
                        placeholder='NSA'
                        component={TextField}
                        label='NSA'
                        className={classes.TextField}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <hr />
            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'space-between', height: 'auto' }}>
              <Field
                name='notes'
                placeholder='Notes'
                component={TextField}
                label='Notes'
                className={classes.TextArea}
                fullWidth
                multiline
                variant='outlined'
                rows={4}
                style={{ marginTop: 50, marginBottom: 50 }}
              />
              <Field
                name='description'
                placeholder='Description of Property'
                component={TextField}
                label='Description of Property'
                className={classes.TextArea}
                fullWidth
                multiline
                variant='outlined'
                rows={4}
                style={{ marginTop: 50, marginBottom: 50 }}
              />
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.buttons}
                onClick={() => history.push('/Lands')}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={classes.buttons}
                disabled={!props.isValid}
              >
                Add Land
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTract;
