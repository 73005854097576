import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ROWS } from '../../components/row/ROWQueries';
import LoadingView from '../../components/LoadingView';
import ROWtable from './ROWTable';
import { Button } from '@material-ui/core';

const SortROWsByAlphabet = (ROWArr = []) => {
  let sorted = [...ROWArr];

  return sorted.sort((a, b) => {
    if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
      return 1;
    } else if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const ROWTableView = ({ history }) => {
  const ROWQuery = useQuery(GET_ALL_ROWS);

  if (ROWQuery.error) {
    return <h1>{ROWQuery.error.toString()}</h1>;
  } else if (ROWQuery.loading) {
    return <LoadingView />;
  }

  const ROWs = SortROWsByAlphabet(ROWQuery.data.ROW) || [];

  return (
    <div>
      <ROWtable data={ROWs} history={history}/>
    </div>
  );
};

export default ROWTableView;
