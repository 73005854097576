import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';

function intersperse(arr, sep) {
  if (arr.length === 0) return [];
  return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}

function getPageNumberFromQueryParams({ page }) {
  if (!page || isNaN(Number(page))) return 0;
  return Number(page);
}

function replaceHistoryWithPage(pageNum, { history }) {
  history.replace(`/Wells?page=${pageNum}`);
}

const WellsTable = ({ data, history }) => {
  const [pageNumber, setPageNumber] = useState(getPageNumberFromQueryParams(queryString.parse(window.location.search)));

  const columns = [
    {
      title: 'Well Name',
      render: (rowData) => (
        <Link
          to={`/Wells/${rowData.id}?name=${encodeURIComponent(rowData.name)}`}
          target='_blank'>
          {rowData.name}
        </Link>
      ),
      field: 'name',
      defaultSort: 'asc',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    {
      title: 'Operator',
      render: (rowData) =>
        !rowData.operatedBy ? (
          <span>No Operator</span>
        ) : (
          <Link
            to={`/Operators/${rowData.operatedBy.id}?name=${encodeURIComponent(rowData.operatedBy.name)}`}
            target='_blank'
            onClick={() => replaceHistoryWithPage(pageNumber, { history })}
          >
            {rowData.operatedBy.name}
          </Link>
        ),
      field: 'operatedBy.name',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    {
      title: 'API #',
      field: 'apiNumber',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    {
      title: 'Status',
      field: 'status',
      defaultSort: 'asc',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: 'OGL',
      render: (rowData) => (rowData.lease ? rowData.lease.leaseName : rowData.OGL || 'N/A'),
      field: 'OGL',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: 'RRC Field',
      field: 'rrcField',
    },
    {
      title: 'RRC Lease Number',
      field: 'rrcLeaseNumber',
    },
    {
      title: 'State',
      field: 'state',
    },
    {
      title: 'County',
      field: 'county',
    },
    {
      title: 'Unit',
      render: (rowData) =>
        !rowData.unit ? (
          <span>N/A</span>
        ) : (
          <Link
            to={`/Units/${rowData.unit.id}?name=${encodeURIComponent(rowData.unit.name)}`}
            target='_blank'
            onClick={() => replaceHistoryWithPage(pageNumber, { history })}
          >
            {rowData.unit.name}
          </Link>
        ),
      field: 'unit',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    },
    {
      title: 'Land Id',
      field: 'landId',
      render: (rowData) => {
        if (!rowData.landId) return 'N/A';
        const splits = rowData.landId.split(',').map((s) => s.trim().replace('\n', ''));
        const links = splits.map((e) => (
          <Link to={`/Lands/${e}?name=${encodeURIComponent(e)}`} key={e} target='_blank'>
            {e}
          </Link>
        ));
        return intersperse(links, ',');
      },
      cellStyle: { whiteSpace: 'nowrap' },
    },
  ];

  const headers = [
    { label: 'Well Name', key: 'name' },
    { label: 'Operator', key: 'operator' },
    { label: 'API #', key: 'apiNumber' },
    { label: 'Status', key: 'status' },
    { label: 'OGL', key: 'OGL' },
    { label: 'RRC Field', key: 'rrcField' },
    { label: 'RRC Lease #', key: 'rrcLeaseNumber' },
    { label: 'State', key: 'state' },
    { label: 'County', key: 'county' },
    { label: 'Unit', key: 'unit' },
    { label: 'Land ID', key: 'landId' },
  ];

  const handleExportCsv = (columns, data) => {
    const exportData = data.map((well) => ({
      name: well.name || '',
      operator: well.operatedBy ? well.operatedBy.name : 'No Operator',
      apiNumber: well.apiNumber || '',
      status: well.status || '',
      OGL: well.lease ? well.lease.leaseName : well.OGL || 'N/A',
      rrcField: well.rrcField || '',
      rrcLeaseNumber: well.rrcLeaseNumber || '',
      state: well.state || '',
      county: well.county || '',
      unit: well.unit ? well.unit.name : 'N/A',
      landId: well.landId || 'N/A',
    }));

    const headerRow = headers.map((h) => `"${h.label}"`).join(',');
    const dataRows = exportData.map((row) =>
      headers
        .map((h) => {
          const value = row[h.key] ?? '';
          return `"${value.toString().replace(/"/g, '""')}"`;
        })
        .join(',')
    );
    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'WellsTable.csv';
    link.click();
  };

  return (
    <div>
      <MaterialTable
        data={data.map((d) => ({ ...d }))}
        columns={columns}
        title=""
        components={{
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#1976d2',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
              }}
            >
              <MTableToolbar {...props} />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => history.push('/addWell')}
                style={{ marginLeft: '8px' }}
              >
                Add Well
              </Button>
            </div>
          ),
        }}
        options={{
          initialPage: pageNumber,
          searchFieldStyle: {
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          selection: false,
          pageSize: data.length,
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
          showTitle: false,
          exportButton: true, // Enable built-in export button
          exportCsv: handleExportCsv, // Use custom CSV export
        }}
        onChangePage={(page) => setPageNumber(page)}
      />
    </div>
  );
};

export default WellsTable;