import React from 'react';
import { useQuery } from '@apollo/client';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GET_ACTIVE_WELLS } from '../../components/wells/WellQueries';

const TableStyles = makeStyles(() => ({
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
    marginBottom: '1rem',
  },
  commentsCell: {
    width: '100px',
    textAlign: 'center',
    padding: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    fontSize: '0.875rem',
    maxWidth: '400px',
    padding: '8px 12px',
    border: '1px solid #ccc',
  },
}));

const WellMaster = () => {
  const classes = TableStyles();
  const { loading, error, data } = useQuery(GET_ACTIVE_WELLS);

  // Loading state
  if (loading) {
    return <Typography>Loading wells...</Typography>;
  }

  // Error state
  if (error) {
    return <Typography>Error loading wells: {error.message}</Typography>;
  }

  // Data from query
  const wells = data?.Well || [];

  // Headers for CSV export
  const headers = [
    { label: 'Well Name', key: 'name' },
    { label: 'API #', key: 'apiNumber' },
    { label: 'Operator', key: 'operator' },
    { label: 'RRC Lease #', key: 'rrcLeaseNumber' },
    { label: 'Permit', key: 'permit' },
    { label: 'land ID', key: 'landId' },
    { label: 'Abstract', key: 'abstract' },
    { label: 'Status', key: 'status' },
    { label: 'Spud Date', key: 'spudDate' },
    { label: 'Rig Release', key: 'rigReleaseDate' },
    { label: 'Date Producing', key: 'dateProducing' },
    { label: 'Date In Pay', key: 'dateInPay' },
    { label: 'OGL', key: 'OGL' },
    { label: 'Location', key: 'location' },
    { label: 'DecProdUnit', key: 'decProdUnit' },
    { label: 'DPU', key: 'dpu' },
    { label: 'Producing Formation', key: 'producingFormation' },
    { label: 'Length of Lateral', key: 'lengthOfLateral' },
    { label: 'RRC Field', key: 'rrcField' },
    { label: 'Payor', key: 'payor' },
    { label: 'Operator', key: 'operator' },
    { label: 'MES DOI', key: 'mesDOI' },
    { label: 'MPI DOI', key: 'mpiDOI' },
    { label: 'SRO DOI', key: 'sroDOI' },
    { label: 'Comments', key: 'notes' },
    { label: 'RRC Lease Notes', key: 'rrcLeaseNotes' },
    { label: 'Operator History', key: 'operatorHistory' },
    { label: 'Unit', key: 'unit' },
  ];

  const columns = [
    { title: 'Well Name', field: 'name', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'API #', field: 'apiNumber', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'Rcc Lease', field: 'rrcLeaseNumber' },
    { title: 'Permit', field: 'permitNumber' },
    {
      title: 'Land Id',
      field: 'landId',
      render: (rowData) => rowData.drilledOn.map((e) => e.id).join(', '),
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    { title: 'Abstract', field: 'abstract' },
    { title: 'Status', field: 'status' },
    { title: 'Spud Date', field: 'spudDate' },
    { title: 'Rig Release', field: 'rigReleaseDate' },
    { title: 'Date Producing', field: 'dateProducing' },
    { title: 'Date In Pay', field: 'dateInPay' },
    { title: 'OGL', field: 'OGL' },
    { title: 'Location', field: 'location' },
    { title: 'DecProdUnit', field: 'decProdUnit' },
    { title: 'DPU', field: 'dpu', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'Producing Formation', field: 'producingFormation' },
    { title: 'Length of Lateral', field: 'lengthOfLateral' },
    { title: 'rrcField', field: 'rrcField' },
    { title: 'Payor', field: 'payor' },
    { title: 'Operator', field: 'operator', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' } },
    { title: 'Family ID', field: 'familyID' },
    {
      title: 'mes DOI',
      field: 'mesDOI',
      render: (rowData) =>
        rowData.divisionOrder[0]?.mesDOI
          ? rowData.divisionOrder[0].mesDOI.toFixed(8).toString()
          : 'Incomplete',
    },
    {
      title: 'mpi DOI',
      field: 'mpiDOI',
      render: (rowData) =>
        rowData.divisionOrder[0]?.mpiDOI
          ? rowData.divisionOrder[0].mpiDOI.toFixed(8).toString()
          : 'Incomplete',
    },
    {
      title: 'sro DOI',
      field: 'sroDOI',
      render: (rowData) =>
        rowData.divisionOrder[0]?.sroDOI
          ? rowData.divisionOrder[0].sroDOI.toFixed(8).toString()
          : 'Incomplete',
    },
    {
      title: 'Comments',
      field: 'notes',
      render: (rowData) => (
        <Tooltip
          title={rowData.notes || 'No comments'}
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          arrow="true" // Fixed: string value to avoid warning
        >
          <span className={classes.commentsCell}>...</span>
        </Tooltip>
      ),
      cellStyle: {
        width: '100px',
        padding: '8px',
      },
    },
    {
      title: 'RRC Lease Notes',
      field: 'rrcLeaseHistory',
      render: (rowData) => (
        <Tooltip
          title={rowData.rrcLeaseHistory || 'No notes'}
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          arrow="true"
        >
          <span className={classes.commentsCell}>...</span>
        </Tooltip>
      ),
      cellStyle: {
        width: '100px',
        padding: '8px',
      },
    },
    {
      title: 'Operator History',
      field: 'operatorHistory',
      render: (rowData) => (
        <Tooltip
          title={rowData.operatorHistory || 'No history'}
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          arrow="true"
        >
          <span className={classes.commentsCell}>...</span>
        </Tooltip>
      ),
      cellStyle: {
        width: '100px',
        padding: '8px',
      },
    },
    { title: 'Unit', field: 'unit', render: (rowData) => rowData.unit?.name || '' },
  ];

  // Custom CSV export function
  const handleExportCsv = (columns, data) => {
    const exportData = wells.map((w) => ({
      name: w.name,
      apiNumber: w.apiNumber,
      rrcLeaseNumber: w.rrcLeaseNumber,
      permit: w.permitNumber || '',
      landId: w.drilledOn.map((e) => e.id).join(', '),
      abstract: w.abstract,
      status: w.status,
      spudDate: w.spudDate,
      rigReleaseDate: w.rigReleaseDate,
      dateProducing: w.dateProducing,
      dateInPay: w.dateInPay,
      OGL: w.lease ? w.lease.leaseName : w.OGL || '',
      location: w.location || '',
      decProdUnit: w.decProdUnit,
      dpu: w.dpu,
      producingFormation: w.producingFormation,
      lengthOfLateral: w.lengthOfLateral,
      rrcField: w.rrcField,
      payor: w.payor || '',
      operator: w.operator,
      familyID: w.familyID,
      mesDOI:
        w.divisionOrder.length && w.divisionOrder[0].mesDOI !== null && typeof w.divisionOrder[0].mesDOI === 'number'
          ? w.divisionOrder[0].mesDOI.toFixed(8).toString()
          : null,
      mpiDOI:
        w.divisionOrder.length && w.divisionOrder[0].mpiDOI !== null && typeof w.divisionOrder[0].mpiDOI === 'number'
          ? w.divisionOrder[0].mpiDOI.toFixed(8).toString()
          : null,
      sroDOI:
        w.divisionOrder.length && w.divisionOrder[0].sroDOI !== null && typeof w.divisionOrder[0].sroDOI === 'number'
          ? w.divisionOrder[0].sroDOI.toFixed(8).toString()
          : null,
      notes: w.notes ? w.notes.replace(/(?:\r\n|\r|\n)/g, '') : '',
      rrcLeaseNotes: w.rrcLeaseHistory ? w.rrcLeaseHistory.replace(/(?:\r\n|\r|\n)/g, '') : '',
      operatorHistory: w.operatorHistory ? w.operatorHistory.replace(/(?:\r\n|\r|\n)/g, '') : '',
      unit: w.unit?.name || '',
    }));

    const headerRow = headers.map((h) => `"${h.label}"`).join(',');
    const dataRows = exportData.map((row) =>
      headers
        .map((h) => {
          const value = row[h.key] ?? '';
          return `"${value.toString().replace(/"/g, '""')}"`;
        })
        .join(',')
    );
    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'WellMaster.csv';
    link.click();
  };

  return (
    <div style={{width: '100vw'}}>
      <MaterialTable
        columns={columns}
        data={wells.map((w) => ({ ...w }))}
        title=""
        components={{
          Toolbar: (props) => (
            <div
              // hidden
              style={{
                backgroundColor: '#1976d2',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'center',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          selection: false,
          exportButton: true,
          exportCsv: handleExportCsv,
          paging: false, // Render all rows
          maxBodyWidth: '100vh',
          maxBodyHeight: '100vh', // Optional: Vertical scroll if too tall
          rowStyle: {
            height: '48px', // Fixed row height
          },
          searchFieldStyle: {
            marginLeft: '-24px',
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
        }}
      />
    </div>
  );
};

export default WellMaster;