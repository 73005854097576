import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext} from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client'


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql',
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('idToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// const cache = new InMemoryCache({
//   typePolicies: {
//     Operator: {
//       fields: {
//         contacts: {
//           merge(existing = [], incoming) {
//             // Merge strategy: replace existing with incoming
//             return incoming;
//           }
//         }
//       }
//     }
//   },
//   dataIdFromObject: (obj) => {
//     switch (obj.__typename) {
//       default: {
//         return obj.id;
//       }
//     }
//   },
// });


const cache = new InMemoryCache({
  // Recommended development-only settings
  freezeResults: process.env.NODE_ENV === 'development', // Disable freezing in dev
  typePolicies: {
    Operator: {
      fields: {
        contacts: {
          merge(existing = [], incoming) {
            return incoming;
          }
        }
      }
    }
  },
  addTypename: true,
});

// const createClient = () =>
//   new ApolloClient({
//     link: authLink.concat(httpLink),
//     cache,
//   });

const createClient = () =>
  new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    // Recommended complementary setting
    assumeImmutableResults: false,
    // Defaults are usually safe, but explicit is better
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    }
  });

const Client = () => {
  let instance;

  if (instance) {
    console.error('Tried to create a second instance!');
    return;
  } else {
    instance = createClient();
  }

  return instance;
};

export default Client();
