import React from 'react';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack/build';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, MenuItem } from '@material-ui/core';
import { CREATE_OPERATOR, DefaultOperator, GET_ALL_OPERATORS } from '../../components/operators/OperatorQueries';
import AddOperatorSchema from '../../components/operators/AddOperatorSchema';
import { Prompt } from 'react-router';
import { useMutation } from '@apollo/client';
import { v4 } from 'uuid';
import queryString from 'query-string';
import { withApollo } from '@apollo/client/react/hoc';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import DeleteOperatorModal from './DeleteOperatorModal';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

function mergeDefaultsWithQueryParams(defaultOperator, queryParameters = {}) {
  const { name } = queryParameters;
  let newOperator = { ...defaultOperator };

  if (name) {
    newOperator.name = name;
  }

  return newOperator;
}

const AddOperator = ({ history }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);

  const [addOperator] = useMutation(CREATE_OPERATOR, {
    update: (store, { data: { CreateOperator } }) => {
      try {
        const operatorQuery = store.readQuery({ query: GET_ALL_OPERATORS });

        const data = { Operator: [...operatorQuery.Operator, CreateOperator] };

        store.writeQuery({ query: GET_ALL_OPERATORS, data });
      } catch (e) {
        console.error(e);
      }
    },
  });

  const onSubmit = (newOperatorFormValues) => {
    return addOperator({
      variables: newOperatorFormValues,
    });
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={mergeDefaultsWithQueryParams(DefaultOperator, queryString.parse(window.location.search))}
          validationSchema={AddOperatorSchema}
          onSubmit={(values, actions) => {
            values.id = v4();
            actions.setSubmitting(true);
            return onSubmit(values).then(
              (value) => {
                enqueueSnackbar('Operator Successfully Created', {variant: 'success'});
                actions.setSubmitting(false);
                history.push(`/Operators/${values.id}`);
              },
              (error) => {
                enqueueSnackbar({error}, {variant: 'error'});
                actions.setSubmitting(false);
                closeSnackbar();
                console.log(error);
              }
            );
          }}
        >
          {(props) => {
            return (
              <>
                <Prompt
                  when={props.dirty && props.submitCount < 1}
                  message='You have not submitted the form, are you sure you want to leave?'
                />
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#1976d2',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  paddingTop: '8px',
                  paddingBottom: '8px'

                }}>
                  <div style={{flex: 'auto'}}></div>
                  <div style={{marginLeft: '64px', flex: 'none'}}>
                    <div style={{display: 'flex', gap: '8px', marginRight: '16px'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => props.handleSubmit()}
                        disabled={!props.isValid}
                        tabIndex={15}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => history.push('/Operators')}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
                <Form style={{marginTop: '16px'}}>
                  <table style={{minWidth: '100%', marginLeft: '20px' }}>
                    <tbody>
                    <tr style={{borderBottom: 'none'}}>
                      <th scope="row" style={{borderBottom: 'none'}}>
                        <label style={{
                          fontWeight: 'normal',
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Company Name:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 1 }}
                          name="name"
                          placeholder="Enter Company Name"
                          component={TextField}
                          style={{minWidth: '270px', fontWeight: 'normal'}}
                        />
                      </th>
                      <td style={{borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Type Dropdown:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 8 }}
                          name="type"
                          component={TextField}
                          select
                        >
                          <MenuItem value="">Select Type</MenuItem>
                          <MenuItem value={'Attorney'}>Attorney</MenuItem>
                          <MenuItem value={'County Gov'}>County Gov</MenuItem>
                          <MenuItem value={'Education'}>Education</MenuItem>
                          <MenuItem value={'Family Contact'}>Family Contact</MenuItem>
                          <MenuItem value={'Family Office'}>Family Office</MenuItem>
                          <MenuItem value={'Lessee'}>Lessee</MenuItem>
                          <MenuItem value={'O&G'}>O&G</MenuItem>
                          <MenuItem value={'Office/Business'}>Office/Business</MenuItem>
                          <MenuItem value={'Regulatory'}>Regulatory</MenuItem>
                          <MenuItem value={'ROW'}>ROW</MenuItem>
                          <MenuItem value={'Surface Contractor'}>Surface Contractor</MenuItem>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Address:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 2 }}
                          name='address1'
                          placeholder='Enter Company Address'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Operator #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 9 }}
                          name='operatorNumber'
                          placeholder='Operator #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          City:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 3 }}
                          name='city'
                          placeholder='City'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Mes Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 10 }}
                          name='mesAccountNumber'
                          placeholder='MES Account #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          State:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 4 }}
                          name='state'
                          placeholder='State'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          MPI Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 11 }}
                          name='mpiAccountNumber'
                          placeholder='MPI Account #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Zip Code:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 5 }}
                          name='zip'
                          placeholder='Zip Code'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          SRO Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 12 }}
                          name='sroAccountNumber'
                          placeholder='SRO Account #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Phone #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 6 }}
                          name='phone'
                          placeholder='Phone #'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Tax Payer ID-CONG:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 13 }}
                          name='taxPayerID'
                          placeholder='Tax Payer ID-CONG'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Website:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 7 }}
                          name='companyWebsite'
                          placeholder='Company Website'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div style={{marginTop: '6px'}}>
                    <label style={{
                      marginRight: '16px',
                      marginLeft: '10px',
                      fontSize: '14px'
                    }}>
                      Notes:
                    </label>
                    <Field
                      inputProps={{ tabIndex: 14 }}
                      name='notes'
                      placeholder='Notes'
                      component={TextField}
                      multiline={true}
                      rows={2}
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                        },
                      }}
                      variant='filled'
                      fullWidth
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
)
  ;
};

export default withApollo(AddOperator);
