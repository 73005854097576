import React from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import {intersperse} from "../units/UnitTable";
import Button from '@material-ui/core/Button';

const ROWtable = ({ data, history }) => {

  const editableROWs = data.map((u) => ({ ...u }));
    editableROWs.forEach((row) => {
    const larr = new Set([]);
    row.locatedOn.map((tract) => larr.add(tract.id));
    row.locatedOn = [...larr].map((i) => i).join(',');
    });

  const columns = [
    {
      title: 'Row Name',
      field: 'projectName',
      defaultSort: 'asc',
      render: (rowData) => (
        <Link
          to={`/RightOfWays/${rowData.id}?name=${encodeURIComponent(rowData.projectName)}`}
          target='_blank'>
          {rowData.projectName === ' ' ? 'None' : rowData.projectName}
        </Link>
      ),
    },
    {
      title: 'Lessee',
      field: 'lessee',
    },
    {
      title: 'Type of ROW',
      field: 'type',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Expiration Date',
      field: 'dateOfExpiration',
      render: (rowData) =>
        moment(rowData.dateOfExpiration).isValid()
          ? moment(rowData.dateOfExpiration).format('MM/DD/YYYY')
          : rowData.dateOfExpiration,
    },
    {
      title: 'Lease Date',
      field: 'dateOfLease',
      render: (rowData) =>
        moment(rowData.dateOfLease).isValid() ? moment(rowData.dateOfLease).format('MM/DD/YYYY') : rowData.dateOfLease,
      customSort: (a, b) => moment.utc(a.dateOfLease).diff(moment.utc(b.dateOfLease))
    },
    {
      title: 'Land ID',
      field: 'locatedOn',
      render: (rowData) => {
        if (rowData.locatedOn.length === 0){
          return <span style={{ color: 'red' }}>Error No Connection To Tract</span>
        }
        else {
          const splits = rowData.locatedOn.split(',');
          const Links = splits.map((e) => (
              <Link to={`/Lands/${e}?name=${encodeURIComponent(e)}`} key={e} target='_blank'>
                {e.trim()}
              </Link>
          ));
          return intersperse(Links, ', ')
        }
      },
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={editableROWs}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              backgroundColor: '#1976d2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 16px',
            }}
          >
            <MTableToolbar {...props} />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push('/addRightOfWay')}
              style={{ marginLeft: '16px' }}
            >
              Add Row
            </Button>
          </div>
        ),
      }}
      options={{
        searchFieldAlignment: 'left',
        exportButton: true,
        pageSize: data.length,
        showTitle: false,
        toolbarButtonAlignment: 'left',
        searchFieldStyle: {
          border: 'none',
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '8px',
          paddingRight: '8px',
          borderRadius: '4px',
          backgroundColor: '#fff',
          color: '#000',
        },
        headerStyle: {
          backgroundColor: '#1976d2',
          color: '#FFF',
          textAlign: 'left',
          justifyContent: 'center',
          whiteSpace: 'pre',
        },
      }}
    />
  );
};

export default ROWtable;
