import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import { Button } from '@material-ui/core';
import UnitTable from './UnitTable';
import { GET_UNITS_FOR_TABLE } from '../../components/units/UnitQueries';

const SortUnitsByAlphabet = (ROWArr = []) => {
  let sorted = [...ROWArr];

  return sorted.sort((a, b) => {
    if (a.name.toLocaleLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const UnitTableView = ({ history }) => {
  const { data, loading, error } = useQuery(GET_UNITS_FOR_TABLE);

  if (error) {
    return <h1>{error.toString()}</h1>;
  } else if (loading) {
    return <LoadingView />;
  }

  const Units = SortUnitsByAlphabet(data.Unit) || [];

  return (
    <div>
      <UnitTable data={Units} history={history} />
    </div>
  );
};

export default UnitTableView;
