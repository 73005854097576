import React from 'react';
import { useQuery } from '@apollo/client';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GET_ALL_ROWS } from '../../components/row/ROWQueries';

const TableStyles = makeStyles(() => ({
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
    marginBottom: '1rem',
  },
}));

const RightOfWay = () => {
  const classes = TableStyles();
  const { loading, error, data } = useQuery(GET_ALL_ROWS);

  // Loading state
  if (loading) {
    return <Typography>Loading right of ways...</Typography>;
  }

  // Error state
  if (error) {
    console.error(error);
    return <Typography variant="h5">Error loading right of ways: {error.message}</Typography>;
  }

  // Data from query
  const rows = data?.ROW || [];

  // Headers for CSV export
  const headers = [
    { label: 'ROW Name', key: 'projectName' },
    { label: 'Status', key: 'status' },
    { label: 'Lessee', key: 'lessee' },
    { label: 'Lease Date', key: 'dateOfLease' },
    { label: 'Term of ROW', key: 'termOfLease' },
    { label: 'Date of Expiration', key: 'dateOfExpiration' },
    { label: 'Type of Lease', key: 'type' },
    { label: 'Vol & Page', key: 'volAndPage' },
    { label: 'Land ID', key: 'landId' },
  ];

  const columns = [
    {
      title: 'ROW Name',
      field: 'projectName',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    { title: 'Status', field: 'status' },
    {
      title: 'Lessee',
      field: 'lessee',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    { title: 'Lease Date', field: 'dateOfLease' },
    {
      title: 'Term of ROW',
      field: 'termOfLease',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    {
      title: 'Date Of Expiration',
      field: 'dateOfExpiration',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
    { title: 'Type of Lease', field: 'type' },
    { title: 'Vol & Page', field: 'volAndPage' },
    {
      title: 'Land ID',
      field: 'landId',
      render: (rowData) => rowData.locatedOn.map((e) => e.id).join(', '),
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    },
  ];

  // Custom CSV export function
  const handleExportCsv = (columns, data) => {
    const exportData = rows.map((r) => ({
      projectName: r.projectName || '',
      status: r.status || '',
      lessee: r.lessee || '',
      dateOfLease: r.dateOfLease || '',
      termOfLease: r.termOfLease || '',
      dateOfExpiration: r.dateOfExpiration || '',
      type: r.type || '',
      volAndPage: r.volAndPage || '',
      landId: r.locatedOn.map((e) => e.id).join(', '),
    }));

    const headerRow = headers.map((h) => `"${h.label}"`).join(',');
    const dataRows = exportData.map((row) =>
      headers
        .map((h) => {
          const value = row[h.key] ?? '';
          return `"${value.toString().replace(/"/g, '""')}"`;
        })
        .join(',')
    );
    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RightOfWay.csv';
    link.click();
  };

  return (
    <div style={{maxWidth: '100vw'}}>
      <MaterialTable
        columns={columns}
        data={rows.map((r) => ({ ...r }))}
        title=""
        components={{
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#1976d2',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'center',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          selection: false,
          exportButton: true,
          exportCsv: handleExportCsv,
          paging: false, // Render all rows
          maxBodyHeight: '80vh', // Optional: Vertical scroll if too tall
          rowStyle: {
            height: '48px', // Fixed row height
          },
          searchFieldStyle: {
            marginLeft: '-24px',
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
        }}
      />
    </div>
  );
};

export default RightOfWay;