import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import UserList from './UserList';
import { USERS_LIST, SET_ROLE, ROLE_LIST, CREATE_USER, DELETE_USER, ADD_STATE, STATE_LIST, UPDATE_STATE, DELETE_STATE, DELETE_COUNTY } from '../../components/admin/adminQueries';
import LoadingView from '../../components/LoadingView';
import CreateUserForm from './CreateUserForm';
import Settings from './AdminSettings';
import { Tabs, Tab, AppBar, Button, Toolbar } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const AdminPage = (props) => {
  const UserListQuery = useQuery(USERS_LIST);
  const UserRoleQuery = useQuery(ROLE_LIST);
  const StateListQuery = useQuery(STATE_LIST);
  const [DeleteState] = useMutation(DELETE_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const [DeleteCounty] = useMutation(DELETE_COUNTY, { refetchQueries: [{ query: STATE_LIST }] });
  const [SetUserRole] = useMutation(SET_ROLE, { refetchQueries: [{ query: USERS_LIST }] });
  const [CreateUser] = useMutation(CREATE_USER, { refetchQueries: [{ query: USERS_LIST }] });
  const [DeleteUser] = useMutation(DELETE_USER, { refetchQueries: [{ query: USERS_LIST }] });
  const [AddState] = useMutation(ADD_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const [UpdateState] = useMutation(UPDATE_STATE, { refetchQueries: [{ query: STATE_LIST }] });
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState(0);
  const [createHandler, setCreateHandler] = useState('');

  if (UserListQuery.error) {
    return <h1>Could not load users!</h1>;
  } else if (UserListQuery.loading) {
    return <LoadingView />;
  }

  const handleCreateClick = () => {
    setCreateHandler('True');
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(e, tabIndex) => setCurrentTab(tabIndex)}
          >
            <Tab label="Users" />
            <Tab label="Create User" />
            <Tab label="Settings" />
          </Tabs>
          {currentTab === 1 && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              form="addUser" // Links to the form in CreateUserForm
              style={{
                marginLeft: 16,
                alignSelf: 'center',
                fontSize: '0.75rem',
                minWidth: 'auto',
                padding: '4px 10px',
              }}
            >
              Submit
            </Button>
          )}
          {currentTab === 2 && (
            <Button
              hidden
              variant="contained"
              size="small"
              color="primary"
              style={{ marginLeft: 16, alignSelf: 'center' }}
              onClick={() => handleCreateClick()}
            >
              Create
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {currentTab === 0 && (
        <UserList
          data={{
            users: UserListQuery.data.Users,
            roles: UserRoleQuery.data.__type.enumValues || [],
          }}
          mutations={{ SetUserRole, DeleteUser }}
        />
      )}
      {currentTab === 1 && (
        <CreateUserForm enqueueSnackbar={enqueueSnackbar} mutations={{ CreateUser }} />
      )}
      {currentTab === 2 && (
        <Settings
          enqueueSnackbar={enqueueSnackbar}
          data={{ states: StateListQuery.data.states, fullData: StateListQuery.data }}
          mutations={{ AddState, UpdateState, DeleteState, DeleteCounty }}
          onCreateNewState={createHandler}
          resetCreate={() => setCreateHandler('')}
        />
      )}
    </div>
  );
};

export default AdminPage;