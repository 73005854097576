import React from 'react';
import Iframe from 'react-iframe';
import LandMapandLegal from './LandMapandLegal';

const LandsLegal = ({ Tract, EditTract, setNotification, mapUrl, mapType, mapStamp, onSubmitMap }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '100vw',
      minHeight: '100vh',
      position: 'relative',
    }}
         className={'col'}
    >
      {/* Timestamp */}
      <div style={{
        textAlign: 'center',
        backgroundColor: '#1976d2',
        color: 'white',
        padding: '10px 0',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 2,
        position: 'sticky',
        top: 0
      }}>
        {mapStamp ? <h6 style={{marginTop: '5px'}}>(Last Updated) <u>{mapStamp.toString()}</u> </h6> : <h6 style={{marginTop: '5px'}}>(NO TIME STAMP YET)</h6>}
      </div>

      {/* Content */}
      <div style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '20px',
        marginTop: '30px',
        marginLeft: '10px'
      }}>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <LandMapandLegal
            Tract={Tract}
            EditTract={EditTract}
            setNotification={setNotification}
            mapType={mapType}
            mapStamp={mapStamp}
            onSubmitMap={onSubmitMap}
          />
        </div>

        {/* Media Image/PDF */}
        <div style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1200px'
        }}>
          {mapUrl ? (
            <Iframe
              url={mapUrl}
              style={{
                width: '100%',
                height: '60vh',
                border: 'none',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            />
          ) : (
            <div style={{
              background: 'linear-gradient(135deg, #1976d2 0%, #c3cfe2 100%)',
              borderRadius: '12px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e2e8f0',
              width: '100%',
              maxWidth: '100%',
              marginBottom: '600px'
            }}>
              <span style={{
                fontSize: '1.25rem',
                fontWeight: 500,
                color: 'white',
                textAlign: 'center',
                marginLeft: '10px',
              }}>
                Awaiting upload ...
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LandsLegal;
