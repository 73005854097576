import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import { GET_ALL_OTHER_LEASES } from '../../components/otherleases/otherleaseQueries';
import OtherLeaseTable from './OtherLeaseTable';

const SortOtherLeasesByAlphabet = (LeaseArr = []) => {
  let sorted = [...LeaseArr];

  return sorted.sort((a, b) => {
    if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
      return 1;
    } else if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const OtherLeaseTableView = ({ history }) => {
  const OtherLeaseQuery = useQuery(GET_ALL_OTHER_LEASES);

  if (OtherLeaseQuery.error) {
    return <h1>{OtherLeaseQuery.error.toString()}</h1>;
  } else if (OtherLeaseQuery.loading) {
    return <LoadingView />;
  }

  const OtherLeases = SortOtherLeasesByAlphabet(OtherLeaseQuery.data.OtherLease) || [];

  return (
    <div>
      <OtherLeaseTable data={OtherLeases} history={history} />
    </div>
  );
};

export default OtherLeaseTableView;
