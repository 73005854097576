import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import LeaseTable from './LeaseTable';
import Button from '@material-ui/core/Button';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import TemplateLeaseTable from './TemplateLeaseTable';
import gql from 'graphql-tag';
import Toolbar from '@material-ui/core/Toolbar';

const LEASE_TABLE_QUERY = gql`
  query {
    Lease {
      id
      leaseName
      status
      effectiveDate
      template
      leaseRecordings {
        id
        county
      }
    }
  }
`;

const SortLeasesByAlphabet = (LeaseArr = []) => {
  let sorted = [...LeaseArr];

  return sorted.sort((a, b) => {
    if (a.leaseName.toLocaleLowerCase() > b.leaseName.toLowerCase()) {
      return 1;
    } else if (a.leaseName.toLowerCase() < b.leaseName.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

function TabContainer(props) {
  return (
    <Typography component='div'>
      {props.children}
    </Typography>
  );
}

const LeaseTableView = ({ history }) => {
  const [value, setValue] = React.useState(0);

  const LeaseQuery = useQuery(LEASE_TABLE_QUERY);

  if (LeaseQuery.error) {
    return <h1>{LeaseQuery.error.toString()}</h1>;
  } else if (LeaseQuery.loading) {
    return <LoadingView />;
  }

  const Leases = SortLeasesByAlphabet(LeaseQuery.data.Lease) || [];

  const nonTemplateLeases = Leases.filter((lease) => lease.template === false || !lease.template);

  const templateLeases = Leases.filter((lease) => lease.template === true);
  const tabStyles = {
    padding: '1px 1px',
  };

  return (
    <>
      <AppBar position='static'>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs value={value}
                variant="scrollable"
                scrollButtons="on"
                sx={{
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'flex-start', // Align tabs to start
                  },
                  '& .MuiTabs-scroller': {
                    overflowX: 'auto !important', // Ensure scrolling works
                  }
                }}
                onChange={(e, newVal) => setValue(newVal)}
          >
            <Tab key={'1'} style={tabStyles} label='Leases' />
            <Tab key={'2'} style={tabStyles} label='Lease Templates' />
          </Tabs>
          {value === 0 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginLeft: '20px'}}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                  padding: '4px 10px',
                }}
                onClick={() => history.push('/addLease')}
              >
                Add Lease
              </Button>
            </div>
          )}
          {value === 1 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginLeft: '20px'}}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    fontSize: '0.75rem',
                    minWidth: 'auto',
                    padding: '4px 10px',
                  }}
                  onClick={() => history.push('/addLeaseTemplate')}
                >
                  Add Lease Template
                </Button>
              </div>
          )}
        </Toolbar>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <LeaseTable data={nonTemplateLeases} history={history} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <TemplateLeaseTable templateData={templateLeases} history={history} />
        </TabContainer>
      )}
    </>
  );
};

export default LeaseTableView;
