import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Button, MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';
import Auth from '../../util/Auth';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  "@global": {
    "@keyframes gradientShift": {
      "0%": {
        backgroundPosition: "0% 50%"
      },
      "50%": {
        backgroundPosition: "100% 50%"
      },
      "100%": {
        backgroundPosition: "0% 50%"
      }
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 201,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: 200,
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
      zIndex: theme.zIndex.drawer + 1,
    },
    backgroundColor: '#1976d2',
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 200,
  },
  content: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logoutButton: {
    color: 'white',
  },
  links: {
    textDecoration: 'none',
  },
});

const Tabs = [
  {
    base: 'Dashboard',
    linkTo: 'Dashboard',
  },
  {
    base: 'Contacts',
    subs: ['addOperator'],
    linkTo: 'Operators',
    requiredRole: PERMISSIONS_LIST.READ.READ_OPERATORS,
  },
  {
    base: 'Lands',
    subs: ['addLand'],
    linkTo: 'Lands',
    requiredRole: PERMISSIONS_LIST.READ.READ_LANDS,
  },
  {
    base: 'Leases - OGL',
    subs: ['addLease'],
    linkTo: 'Leases',
    requiredRole: PERMISSIONS_LIST.READ.READ_LEASE,
  },
  {
    base: 'Leases - Other',
    subs: ['AddOtherLease'],
    linkTo: 'OtherLeases',
    requiredRole: PERMISSIONS_LIST.READ.READ_OTHER_LEASE,
  },
  {
    base: 'Reports',
    linkTo: 'Reports',
  },
  {
    base: 'ROW',
    subs: ['addRightOfWay'],
    linkTo: 'RightOfWays',
    requiredRole: PERMISSIONS_LIST.READ.READ_ROWS,
  },
  {
    base: 'Units',
    subs: ['addUnits'],
    linkTo: 'Units',
    requiredRole: PERMISSIONS_LIST.READ.READ_UNITS,
  },
  {
    base: 'Wells',
    subs: ['addWell'],
    linkTo: 'Wells',
    requiredRole: PERMISSIONS_LIST.READ.READ_WELLS,
  },
];

const getTabs = (tabs) => tabs.filter((tab) => (tab.requiredRole ? Auth.hasRole(tab.requiredRole) : true));

const hasSubs = (tab) => (tab.subs && Array.isArray(tab.subs) && tab.subs.length > 0 ? true : false);

const isInSubs = (tab, str) => {
  if (!hasSubs(tab)) {
    return false;
  }

  return tab.subs.includes(str);
};

const getInitialTab = (path) => {
  const found = Tabs.findIndex((tab) => tab.linkTo.toLowerCase() === path.toLowerCase() || isInSubs(tab, path));
  return found ? found : 0;
};

const TabMenu = ({ Tabs, classes }) => {
  const path = window.location.pathname.split('/')[1];
  const [currentTab, setCurrentTab] = useState(getInitialTab(path));

  return (
    <>
      {getTabs(Tabs).map((tab, index) => {
        return (
          <div key={tab.base}>
            <Divider />
            <Link to={`/${tab.linkTo}`} className={classes.links} onClick={setCurrentTab}>
              <ListItem button key={tab.base} selected={index === currentTab}>
                <ListItemText primary={tab.base} />
              </ListItem>
            </Link>
          </div>
        );
      })}
    </>
  );
};

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    menuAnchor: null,
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleTabClick = (index) => {
    this.setState({ currentTab: index });
  };

  handleMenuButtonClick = (e) => {
    this.setState({ menuAnchor: e.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchor: null });
  };

  render() {
    const { classes, theme} = this.props;
    const development = window.location.href.includes('monroe.dev.zelif.cam');
    const fullPath = window.location.pathname.split('/')[2];
    const path = window.location.pathname.split('/')[1];
    const currentTab = getInitialTab(path);
    const params = new URLSearchParams(window.location.search);
    const simpleName = params.get('name');
    // topNavText renders the top Navigation text based on the currentTab
    const topNavText = (currentTab) => {

      const baseTabs = {
        "-1" : "ADMINISTRATIVE OPTIONS",
        0: "MONROE PROPERTIES, INC. LAND MACHINE",
        1: "CONTACTS",
        2: "LANDS",
        3: "OGL LEASES",
        4: "OTHER LEASES",
        5: "REPORTS",
        6: "RIGHT OF WAYS",
        7: "UNITS",
        8: "WELLS"
      }

      if (simpleName){
        return simpleName
      }
      return baseTabs[currentTab]

    }
    const appBarStyle = development
      ? {
        // background: 'linear-gradient(45deg, #d32f2f, #ef5350, #f06292, #ffcdd2)',// Reds
        background: 'red',
        // background: 'linear-gradient(45deg, #f06292, #ba68c8, #9575cd, #7986cb)',// Pink - Purple
        // background: 'linear-gradient(45deg, #b3e5fc, #b0bec5, #c5cae9, #d1c4e9)',// Cool Blues
        // backgroundSize: '400% 400%',
        animation: 'gradientShift 15s ease infinite',
      }
      : null;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar} style={appBarStyle}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant='h6' color='inherit' className={classes.grow} noWrap>
              <img src='/img/mp_nav.svg' alt="White Icon" style={{ height: '50px', opacity: 0.9, marginRight: 50 }} />
              {/*{ development ? 'DEVELOPMENT' : 'MONROE PROPERTIES, INC. LAND MACHINE' }*/}
            </Typography>
            <Typography variant='h4' color='inherit' className={classes.grow} style={{ fontWeight: 'bold'}} noWrap>
              {topNavText(currentTab.toString()) || "MONROE PROPERTIES, INC. LAND MACHINE"}
            </Typography>

            <Button
              variant='contained'
              style={{ boxShadow: 'none' }}
              onClick={this.handleMenuButtonClick}
              aria-owns={this.state.menuAnchor ? 'menu-list-grow' : undefined}
            >
              Account <DownArrowIcon />
            </Button>

            <Popper anchorEl={this.state.menuAnchor} open={Boolean(this.state.menuAnchor)} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleMenuClose}>
                      <MenuList>
                        {Auth.hasRole(PERMISSIONS_LIST.ADMIN) ? (
                          <MenuItem onClick={() => this.props.history.push('/admin')}>Admin</MenuItem>
                        ) : null}
                        <MenuItem onClick={() => Auth.logout()}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation='css'>
            <Drawer
              container={this.props.container}
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div>
                <div className={classes.toolbar} />
                <List style={{ padding: 0 }}>
                  <TabMenu Tabs={Tabs} classes={classes} />
                </List>
              </div>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation='css'>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant='permanent'
              open
            >
              <div>
                <div className={classes.toolbar} />
                <Divider />
                <List style={{ padding: 0 }}>
                  <TabMenu Tabs={Tabs} classes={classes} />
                </List>
                <Divider />
              </div>
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {React.cloneElement(this.props.children, { ...this.props })}
        </main>
      </div>
    );
  }
}

export default withTheme()(withRouter(withStyles(styles, { withTheme: true })(ResponsiveDrawer)));
