import React from 'react';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const LeaseTable = ({ data, history }) => {

  const editableLeases = data.map((o) => ({ ...o }));

  editableLeases.forEach((lease) => {
    const countyList = lease.leaseRecordings.map((rec) => rec.county);
    lease.counties = countyList.filter((item, index) => countyList.indexOf(item) === index).join(', ');
  });

  const headers = [
    { label: 'Lease', key: 'leaseName' },
    { label: 'Status', key: 'status' },
    { label: 'County', key: 'counties' },
    { label: 'Effective Date', key: 'effectiveDate' },
  ];

  const columns = [
    {
      title: 'Lease',
      field: 'leaseName',
      render: (rowData) => (
        <Link
          to={`/Leases/${rowData.id}?name=${encodeURIComponent(rowData.leaseName)}`}
              target="_blank">
          {rowData.leaseName}
        </Link>
      ),
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    },
    {
      title: 'County',
      field: 'counties',
      render: (rowData) => (!rowData.counties ? 'N/A - No Recordings' : rowData.counties),
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    },
    {
      title: 'Effective Date',
      field: 'effectiveDate',
      render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
    },
  ];

  // Custom CSV export function
  const handleExportCsv = (columns, data) => {
    const exportData = editableLeases.map((lease) => ({
      leaseName: lease.leaseName || '',
      status: lease.status || '',
      counties: lease.counties || 'N/A - No Recordings',
      effectiveDate: lease.effectiveDate ? moment(lease.effectiveDate).format('MM/DD/YYYY') : '',
    }));

    const headerRow = headers.map((h) => `"${h.label}"`).join(',');
    const dataRows = exportData.map((row) =>
      headers
        .map((h) => {
          const value = row[h.key] ?? '';
          return `"${value.toString().replace(/"/g, '""')}"`;
        })
        .join(',')
    );
    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'LeaseTable.csv';
    link.click();
  };

  return (
    <div>
      <MaterialTable
        data={editableLeases}
        columns={columns}
        title=""
        components={{
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#1976d2',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Action: (props) => (
            <Button
              onClick={() => history.push(`/CloneLease/${props.data.id}?name=${encodeURIComponent(props.data.leaseName)} (Clone)`)}
              color="secondary"
              variant="outlined"
              style={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              size="small"
            >
              Clone Lease
            </Button>
          ),
        }}
        options={{
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
          },
          selection: false,
          exportButton: true,
          exportCsv: handleExportCsv,
          paging: false, // Render all rows
          searchFieldStyle: {
            border: 'none',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            color: '#000',
          },
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
          showTitle: false,
          actionsColumnIndex: 1,
        }}
        actions={[
          () => ({
            onClick: (event, rowData) => history.push(`/CloneLease/${rowData.id}`),
          }),
        ]}
      />
    </div>
  );
};

export default LeaseTable;