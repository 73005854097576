import React from 'react';
import { Typography } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import LoadingView from '../../components/LoadingView';
import WellsTable from './WellsTable';
import gql from 'graphql-tag';

const WELL_TABLE_QUERY = gql`
    query {
        Well {
            id
            _id
            apiNumber
            name
            OGL
            status
            rrcField
            rrcLeaseNumber
            county
            state
            landId
            drilledOn {
                id
            }
            operatedBy {
                id
                name
            }
            unit {
                id
                name
            }
            lease {
                _id
                id
                leaseName
            }
        }
    }
`;

const SortWellsByAlphabet = (WellArr = []) => {
  let sorted = [...WellArr];
  return sorted.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });
};

const Wells = ({ history }) => {
  return (
    <Query query={WELL_TABLE_QUERY}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);
          return <Typography variant="h5">{error.toString()}</Typography>;
        }
        if (loading) return <LoadingView />;

        const wells = SortWellsByAlphabet(data.Well || []);
        return <WellsTable data={wells} history={history} />;
      }}
    </Query>
  );
};

export default Wells;