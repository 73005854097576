import React, { useRef, useState } from 'react';
import { Typography, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Edit, Delete} from '@material-ui/icons';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import Auth from '../../../util/Auth';
import { Form, Field, Formik, FieldArray } from 'formik';
import {TractSchema} from '../../../components/tracts/TractSchema';
import {TextField, Switch} from 'formik-material-ui';
import TractMapCard from '../../maps/TractMapCard';
import LoadingView from '../../../components/LoadingView';
import math from 'mathjs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PERMISSIONS_LIST from '../../../util/RolesEnum';
import Fade from '@material-ui/core/Fade';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { v4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_TRACT_MINERAL_OWNERSHIP,
  ADD_TRACT_SURFACE_OWNERSHIP,
  REMOVE_TRACT_MINERAL_OWNERSHIP,
  REMOVE_TRACT_SURFACE_OWNERSHIP,
} from '../../../components/tracts/TractQueries';
import gql from 'graphql-tag';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const OWNERS_QUERY = gql`
    query {
        Owner {
            id
            name
        }
    }
`;

const TRACT_OWNERSHIP_FRAGMENT = gql`
    fragment TractOwnershipFragment on Tract {
        id
        mineralsOwned {
            id
            netMineralAcres
            mineralFraction
            mineralPercentage
            volPage
            familyMember
            propOwnership
            Owner {
                id
                name
            }
        }
        surfaceOwned {
            id
            netSurfaceAcres
            surfaceFraction
            surfacePercentage
            Owner {
                id
                name
            }
        }
        npri {
            id
            mineralFraction
            mineralPercentage
            Owner {
                id
                name
            }
        }
    }
`;
const getInfoFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }
  return queryResult;
};
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableCell: {
      root: {
        // Base styling for all <TableCell>
        padding: '0px 0px',
        borderBottom: '1px solid #ccc',
      },
      head: {
        // Specifically for header cells (th)
        fontSize: '0.76rem',
        backgroundColor: '#1976d2',
        color: '#fff',
        height: '10px',
      },
      body: {
        // Specifically for body cells (td)
        fontSize: '0.76rem',
        color: '#333',
      },
      // If we want "padding='checkbox'" or "size='small'" variants,
      paddingCheckbox: {
        // The cell containing checkboxes
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTable: {
      root: {
        padding: '0px 0px',
        backgroundColor: 'transparent',
      }
    },
    MuiTableRow: {
      head: {
        position: 'relative',
        padding: '0px 0px',
        zIndex: 1,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        height: '1px',
        minHeight: '1px',
        '& th': {
          padding: '0px 0px',
        },
      },
    },
  },
});


const EditFormStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
  dateInput: {
    '&::-webkit-calendar-picker-indicator': {
      display: 'none',
      '-webkit-appearance': 'none',
    },
    '&::-moz-calendar-picker-indicator': {
      display: 'none'
    },
    '&::-ms-clear': {
      display: 'none'
    },
    '&::-ms-expand': {
      display: 'none'
    },
    '&:hover': {
      cursor: 'text'
    }
  },
  actionCell: {
    width: '80px', // Adjust based on icon sizes
  },

}));

const mineralClassifiedText = (
    <Typography
        style={{
            color: '#1976d2',
            display: 'flex',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            marginLeft: '10px',
            fontSize: '1.2rem',
            overflowWrap: 'anywhere',
            whiteSpace: 'normal',
        }}
    >
        Mineral Classified - Family owns surface, State owns minerals. Family is agent for State, Family & State share
        50/50
        all lease/well/surface payments.
    </Typography>
);

const submitForm = (
    values,
    actions,
    {submitValues, Tract, updateMineralOwnership, updateSurfaceOwnership, EditTract, open}
) => {
    //check values on update
    const parsedValues = submitValues(values);

    actions.setSubmitting(true);

    if (
        math.number(Tract.grossMineralAcres) !== math.number(values.grossMineralAcres) ||
        Tract.mineralFraction !== values.mineralFraction
    ) {
        const overGross = math.number(values.grossMineralAcres);
        const overDecimal = math.number(math.fraction(values.mineralFraction));
        const overNet = math.multiply(overGross, overDecimal);
        Promise.all(
            values.mineralsOwned.map((m, i) => {
                const mineralFraction = math.fraction(m.mineralFraction);
                const mineralPercentage = math.number(mineralFraction);
                const netMineralAcres = math.multiply(mineralPercentage, overNet);

                const update = {
                    relationshipID: m.id,
                    mineralFraction: m.mineralFraction,
                    mineralPercentage: Number(mineralPercentage.toFixed(8)),
                    netMineralAcres,
                };

                actions.setFieldValue(`mineralsOwned[${i}]`, {
                    ...update,
                    id: m.id,
                    Owner: {
                        name: m.Owner.name,
                    },
                });

                return updateMineralOwnership(update)
            })
        ).catch((error) => console.log(error));
    }

    if (
        math.number(Tract.grossSurfaceAcres) !== math.number(values.grossSurfaceAcres) ||
        Tract.surfaceFraction !== values.surfaceFraction
    ) {
        const overGross = math.number(values.grossSurfaceAcres);
        const overDecimal = math.number(math.fraction(values.surfaceFraction));
        const overNet = math.multiply(overGross, overDecimal);
        Promise.all(
            values.surfaceOwned
                .map((m, i) => {
                    const surfaceFraction = math.fraction(m.surfaceFraction);
                    const surfacePercentage = math.number(surfaceFraction);
                    const netSurfaceAcres = math.multiply(surfacePercentage, overNet);

                    const update = {
                        relationshipID: m.id,
                        surfaceFraction: m.surfaceFraction,
                        surfacePercentage,
                        netSurfaceAcres,
                    };

                    actions.setFieldValue(`surfaceOwned[${i}]`, {
                        ...update,
                        id: m.id,
                        Owner: {
                            name: m.Owner.name,
                        },
                    });

                    return updateSurfaceOwnership(update)
                        .catch((error) => console.log(error));
                })
        );
    }

    return EditTract({
        variables: parsedValues,
    })
        .then((data) => {
            open('Successfully updated ownership!', {variant: 'success'});
            actions.setSubmitting(false);
        })
        .catch((error) => {
            console.error(error);
            open(error.toString(), {
                variant: 'error',
            });
            actions.setSubmitting(false);
        });
};

const checkOwnership = (ownerShip) => {
  const value = parseFloat(ownerShip);
  return !isNaN(value) && value !== 0;
}

const EditTractForm = ({
                           Tract,
                           EditTract,
                           snackbarControls,
                           DeleteTract,
                           history,
                           submitValues,
                           updateMineralOwnership,
                           updateSurfaceOwnership,
                           StateCounties,
                       }) => {
    const {open} = snackbarControls;
    const classes = EditFormStyles();
    const [minFracPercent, setMinFracPercent] = React.useState(math.number(math.fraction(Tract.mineralFraction)));
  const [nsa, setNSA] = React.useState(Tract.familyNSA);
    const [surfaceFracPercent, setSurfaceFracPercent] = React.useState(math.number(math.fraction(Tract.surfaceFraction)));
    const [removeMinerals] = useMutation(REMOVE_TRACT_MINERAL_OWNERSHIP);
    const [addMinerals] = useMutation(ADD_TRACT_MINERAL_OWNERSHIP);
    const [addSurface] = useMutation(ADD_TRACT_SURFACE_OWNERSHIP);
  const [removeSurface] = useMutation(REMOVE_TRACT_SURFACE_OWNERSHIP);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [mineralRowId, setMineralRowId] = useState(null);
  const tableRef = useRef(null);

  const { data, loading, error } = useQuery(OWNERS_QUERY);
  if (loading) {
    return <LoadingView />;
  }

  if (error) {
    return error.toString();
  }
  let counties = {}
  if (StateCounties?.states) StateCounties.states.states.forEach(state => {counties[state.stateName] = state.counties})

  const owners = getInfoFromQuery(data.Owner) || [];
  // IF YOU PLAN ON CHANGING THE INITIAL VALUES OR ADDING NEW ONES FOR NEW FIELDS, DO NOT FORGET TO UPDATE.
  // components/TractSchema.js, If you plan on validating that is i.e. validationSchema={TractSchema}

    return (
        <>
          <MuiThemeProvider theme={theme}>

            <Formik
              initialValues={{
                id: Tract.id || '',
                acreage: Tract.acreage || '',
                name: Tract.name || '',
                overRidingRoyaltyInterest: Tract.overRidingRoyaltyInterest || false,
                orriPercentage: Tract.orriPercentage || '0',
                npriPercentage: Tract.npriPercentage || '0',
                waterAcres: Tract.waterAcres || '',
                calicheAcres: Tract.calicheAcres || 0,
                otherMineralAcres: Tract.otherMineralAcres || 0,
                familyID: Tract.familyID || '',
                mineralsOwned: Tract.mineralsOwned || [],
                surfaceOwned: Tract.surfaceOwned || [],
                familyNMA: Tract.familyNMA || '',
                bfNumber: Tract.bfNumber || '',
                abstract: Tract.abstract || '',
                description: Tract.description || '',
                drivingDirections: Tract.drivingDirections || '',
                state: Tract.state || '',
                county: Tract.county || '',
                dateOfPatent: Tract.dateOfPatent || '',
                patentInformation: Tract.patentInformation || '',
                purchaser: Tract.purchaser || '',
                docType: Tract.docType || '',
                recording: Tract.recording || '',
                purchaseDate: Tract.purchaseDate || '',
                survey: Tract.survey || '',
                color: Tract.color || '',
                mineralClassified: Tract.mineralClassified ?? false,
                npriInterest: Tract.npriInterest ?? false,
                waterOwnership: Tract.waterOwnership ?? false,
                otherMinerals: Tract.otherMinerals ?? false,
                caliche: Tract.caliche ?? false,
                grossMineralAcres: Tract.grossMineralAcres || '0',
                mineralFraction: Tract.mineralFraction || '',
                grossSurfaceAcres: Tract.grossSurfaceAcres || '0',
                surfaceFraction: Tract.surfaceFraction || '',
                notes: Tract.notes || '',
                finalMinPercent: (minFracPercent * 100).toFixed(8) || '',
                legalDescription: Tract.legalDescription || '',
                geometry: Tract.geometry || '',
                finalSurfacePercent: (surfaceFracPercent * 100).toFixed(8) || '',
                familyNSA: nsa || '',
                blk: Tract.blk || '',
                surfaceOwnershipPercentage: Tract.surfaceOwnershipPercentage || '',
                range: Tract.range || '',
                township: Tract.township || '',
                section: Tract.section || '',
                tract: Tract.tract || '',
                lot: Tract.lot || '',
                volumePage: Tract.volumePage || '',
              }}
              validationSchema={TractSchema}
                onSubmit={(values, actions) => {
                    submitForm(values, actions, {
                        submitValues,
                        Tract,
                        updateMineralOwnership,
                        updateSurfaceOwnership,
                        EditTract,
                        open,
                    });
                }}
            >
                {(props) => {
                    setMinFracPercent(math.number(math.fraction(Tract.mineralFraction)));
                    setSurfaceFracPercent(math.number(math.fraction(Tract.surfaceFraction)));
                    setNSA(math.multiply(surfaceFracPercent, math.number(Tract.grossSurfaceAcres)).toFixed(8));

                    return (
                      <Form id="editTractForm">
                        <div style={{ display: 'flex', direction: 'row' }}>
                          <Field
                            name="name"
                            placeholder="Land ID"
                            style={{
                              minWidth: 1,
                              padding: '0 8px',
                              marginLeft: '8px',
                            }}
                            component={TextField}
                            label="Land ID"
                            className={classes.TextField}
                          />
                          <Field
                            name="familyID"
                            placeholder="Family ID"
                            component={TextField}
                            label="Family ID"
                            className={classes.TextField}
                          />
                          <Field
                            name="bfNumber"
                            placeholder="BF #"
                            component={TextField}
                            label="BF #"
                            className={classes.TextField}
                          />
                          <Field
                            name="state"
                            select
                            placeholder="State"
                            component={TextField}
                            label="State"
                            className={classes.TextField}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: 200 }}
                            SelectProps={{
                              native: false, // Use non-native select
                              onChange: (e) => {
                                props.handleChange(e);
                                props.setFieldValue('state', e.target.value);
                              }
                            }}

                          >
                              {/* Show the initial value as an option if it exists and isn't in the states list */}
                              {props.values.state &&
                                !StateCounties?.states?.states?.some(state => state.stateName === props.values.state) && (
                                  <MenuItem key="initial" value={props.values.state}>
                                    {props.values.state} (legacy)
                                  </MenuItem>
                                )}
                              {/* List all available states from the query */}
                              {StateCounties?.states?.states?.map((state) => (
                                <MenuItem key={state.id} value={state.stateName}>
                                  {state.stateName}
                                </MenuItem>
                              ))}
                          </Field>
                          <Field
                            name="county"
                            select
                            placeholder="County"
                            component={TextField}
                            label="County"
                            className={classes.TextField}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: 200 }}
                            SelectProps={{
                              native: false,
                              onChange: (e) => {
                                props.setFieldValue('county', e.target.value);
                              }
                            }}
                          >
                            {/* Show initial value if it exists and no counties for selected state, or state not selected */}
                            {props.values.county &&
                              (!props.values.state ||
                                !counties[props.values.state] ||
                                !counties[props.values.state].some(county => county.name === props.values.county)) && (
                                <MenuItem key="initial" value={props.values.county}>
                                  {props.values.county} (legacy)
                                </MenuItem>
                              )}
                            {/* Show counties for selected state if they exist */}
                            {props.values.state &&
                            counties[props.values.state] &&
                            counties[props.values.state].length > 0 ? (
                              counties[props.values.state].map((county) => (
                                <MenuItem key={county.id} value={county.name}>
                                  {county.name}
                                </MenuItem>
                              ))
                            ) : null}
                          </Field>
                          <Field
                            name="abstract"
                            placeholder="Abstract"
                            component={TextField}
                            label="Abstract"
                            className={classes.TextField}
                          />
                          <Field
                            name="survey"
                            placeholder="Document Type"
                            component={TextField}
                            label="Survey"
                            className={classes.TextField}
                          />
                          <Field
                            name="color"
                            select
                            label="Type"
                            component={TextField}
                            className={classes.TextField}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: 200 }}
                          >
                            <MenuItem value={'M'}>Minerals</MenuItem>
                            <MenuItem value={'M&S'}>Minerals & Surface</MenuItem>
                            <MenuItem value={'S'}>Surface</MenuItem>
                          </Field>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Field
                            name="description"
                            placeholder="Description"
                            component={TextField}
                            label="Short Description"
                            className={classes.TextField}
                            fullWidth
                            style={{
                              width: 500,
                              marginLeft: '8px',
                            }}
                          />
                          <FormControlLabel
                            control={<Field name="mineralClassified" component={Switch} color="primary" />}
                            label="Mineral Classified"
                            style={{marginLeft: '110px'}}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            control={<Field name="npriInterest" component={Switch} color="primary" />}
                            label="NPRI"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            control={<Field name="overRidingRoyaltyInterest" component={Switch} color="primary" />}
                            label="ORRI"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            control={<Field name="waterOwnership" component={Switch} color="primary" />}
                            label="Water"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            control={<Field name="caliche" component={Switch} color="primary" />}
                            label="Caliche"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            control={<Field name="otherMinerals" component={Switch} color="primary" />}
                            label="Other Minerals"
                            labelPlacement="top"
                          />
                        </div>
                        <div
                          style={{
                            paddingRight: '15px',
                          }}
                        >
                          {props.values.mineralClassified ? mineralClassifiedText : ''}
                        </div>
                        <div style={{ display: 'flex', paddingLeft: '150px', paddingRight: '100px' }}>
                          <Field
                            name="npriPercentage"
                            placeholder="NRPI decimal"
                            component={TextField}
                            label="NPRI decimal"
                            className={classes.TextField}
                            style={{
                              display: props.values.npriInterest ? 'block' : 'none',
                              marginLeft: '8px',
                            }}
                          />
                          <Field
                            name="orriPercentage"
                            placeholder="ORRI decimal"
                            component={TextField}
                            label="ORRI decimal"
                            className={classes.TextField}
                            style={{
                              display: props.values.overRidingRoyaltyInterest ? 'block' : 'none',
                              marginLeft: '8px',
                            }}
                          />
                          <Field
                            name="waterAcres"
                            placeholder="Water Acres"
                            component={TextField}
                            label="Water Acres"
                            className={classes.TextField}
                            style={{
                              display: props.values.waterOwnership ? 'block' : 'none',
                              marginLeft: '8px',
                            }}
                          />
                          <Field
                            name="calicheAcres"
                            placeholder="Caliche Acres"
                            component={TextField}
                            label="Caliche Acres"
                            type="number"
                            className={classes.TextField}
                            style={{
                              display: props.values.caliche ? 'block' : 'none',
                              marginLeft: '8px',
                            }}
                          />
                          <Field
                            name="otherMineralAcres"
                            placeholder="Other Mineral Acres"
                            component={TextField}
                            type="number"
                            label="Other Minerals Acres"
                            className={classes.TextField}
                            style={{
                              display: props.values.otherMinerals ? 'block' : 'none',
                              marginLeft: '8px',
                            }}
                          />
                        </div>
                        <div style={{
                          display: 'flex',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          position: 'relative',
                          background: '#1976d2',
                          zIndex: 2,
                        }}>
                            <Typography color="textSecondary"
                                        style={{
                                          marginLeft: '8px',
                                          paddingTop: '5px',
                                          color: 'white',
                                        }}
                                        gutterBottom>
                              PATENT INFORMATION
                            </Typography>
                            <Typography color="textSecondary"
                                        style={{
                                          marginLeft: '390px',
                                          paddingTop: '5px',
                                          color: 'white',
                                        }}
                                        gutterBottom>
                              ORIGINAL FAMILY PURCHASE
                            </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Field
                            name="patentInformation"
                            placeholder="Patent Information"
                            component={TextField}
                            label="Name on Patent"
                            className={classes.TextField}
                            style={{
                              marginLeft: '8px',
                            }}
                          />
                          <Field
                            name="dateOfPatent"
                            label="Date Of Patent"
                            placeholder="MM-DD-YYYY"
                            type="date"
                            component={TextField}
                            className={classes.TextField}
                            margin={'normal'}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              classes: {
                                input: classes.dateInput,
                              },
                              placeholder: 'MM-DD-YYYY',
                            }}
                          />
                          <Field
                            name="recording"
                            placeholder="Recording"
                            component={TextField}
                            label="V/P"
                            className={classes.TextField}
                          />
                          <Field
                            name="purchaser"
                            placeholder="Purchaser"
                            component={TextField}
                            label="Purchaser"
                            style={{
                              marginLeft: '80px',
                            }}
                            className={classes.TextField}
                          />
                          <Field
                            name="purchaseDate"
                            label="Date"
                            placeholder="MM-DD-YYYY"
                            type="date"
                            component={TextField}
                            className={classes.TextField}
                            margin={'normal'}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              classes: {
                                input: classes.dateInput,
                              },
                              placeholder: 'MM-DD-YYYY',
                            }}
                          />
                          <Field
                            name="docType"
                            placeholder="Document Type"
                            component={TextField}
                            label="Document Type"
                            className={classes.TextField}
                          />
                          <Field
                            name="acreage"
                            placeholder="acreage"
                            component={TextField}
                            label="Acreage"
                            className={classes.TextField}
                          />
                          <Field
                            name="volumePage"
                            placeholder="V/P"
                            component={TextField}
                            label="V/P"
                            className={classes.TextField}
                          />
                        </div>
                        <div style={{
                          display: 'flex',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          background: '#1976d2',
                          position: 'relative',
                        }}>

                          <Typography color="textSecondary"
                                      style={{
                                        marginLeft: '8px',
                                        paddingTop: '5px',
                                        color: 'white',
                                      }}
                                      gutterBottom>
                            MINERAL INTEREST

                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Field
                            name="grossMineralAcres"
                            placeholder="GMA"
                            component={TextField}
                            label="GMA"
                            style={{
                              marginLeft: '8px',
                              padding: '0px 0px',
                            }}
                            className={classes.TextField}
                          />
                          <Field
                            name="mineralFraction"
                            placeholder="Mineral Fraction"
                            component={TextField}
                            label="Mineral Fraction"
                            style={{
                              padding: '0px 0px',
                            }}
                            className={classes.TextField}
                            disabled
                          />
                          <Field
                            name="finalMinPercent"
                            placeholder="Mineral Percent"
                            component={TextField}
                            label="MIN%"
                            style={{
                              padding: '0px 0px',
                            }}
                            className={classes.TextField}
                            disabled
                          />
                          <Field
                            name="familyNMA"
                            placeholder="Net Mineral Acres"
                            component={TextField}
                            label="NMA"
                            style={{
                              padding: '0px 0px',
                            }}
                            className={classes.TextField}
                            disabled
                          />

                          <div>
                              <Fade in={checkOwnership(props.values.grossMineralAcres)}>
                              <div
                                className={classes.paper}
                                style={{ marginTop: '-55px' }}
                              >
                                <div>
                                  <FieldArray name="mineralsOwned">
                                    {({ remove, insert, push }) => {
                                      let minerals = props.values.mineralsOwned.length ? props.values.mineralsOwned : null
                                      const newMinerals = minerals ? minerals.map(obj => ({
                                        originalId: obj.id,
                                        edit: false,
                                        delete: false
                                      })) : null
                                      const mineralMath = (minFraction = 0) => {
                                        minFraction = math.fraction(minFraction);
                                        const percentage = math.number(minFraction);

                                        return {
                                          mineralPercentage: percentage || 0,
                                          netMineralAcres: math.multiply(percentage, Tract.familyNMA) || 0,
                                        };
                                      };

                                      const columns = [
                                        {
                                          title: (
                                            <AddIcon
                                              style={{fontSize: '30px',
                                                color: '#fff',
                                                float: 'right',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                const plusDiv = document.getElementById('myPlus');
                                                if (plusDiv) {
                                                  plusDiv.click();
                                                }
                                              }}
                                              fontSize="small" />
                                          ),
                                          field: 'dummy', // or any field name
                                          editable: 'never',
                                          headerStyle: {
                                            width: '1px',     // or even '20px'
                                            textAlign: 'center'
                                          },
                                          cellStyle: {
                                            whiteSpace: 'nowrap',
                                            padding: '0px 0px 0px 0px',
                                            borderBottom: 'none',
                                          },
                                          render: () => null
                                        },
                                        {
                                          title: 'Owner',
                                          field: 'Owner.name',
                                          defaultSort: 'asc',
                                          editComponent: ({ onChange }) => (
                                            <Select
                                              options={owners}
                                              getOptionLabel={(option) => option.name}
                                              getOptionValue={(option) => option.id}
                                              onChange={({ id, name }) => onChange({ id, name })}
                                            />
                                          ),
                                          editable: 'onAdd',
                                        },
                                        {
                                          title: 'Mineral Percentage',
                                          field: 'mineralPercentage',
                                          render: (rowData) => (!rowData ? '0' : rowData.mineralPercentage.toFixed(8)),
                                          editable: 'never',
                                        },
                                        {
                                          title: 'Fraction',
                                          field: 'mineralFraction',
                                        },
                                        {
                                          title: 'NMA',
                                          field: 'netMineralAcres',
                                          render: (rowData) => (!rowData ? '0' : rowData.netMineralAcres.toFixed(8)),
                                          editable: 'never',
                                        },
                                      ];

                                      return (
                                        <MaterialTable
                                          components={{
                                            Toolbar: (props) => (
                                              <div
                                                hidden
                                                style={{
                                                  height: "0px",
                                                }}
                                              >
                                                <MTableToolbar {...props} />
                                              </div>
                                            ),
                                          }}
                                          tableRef={tableRef}
                                          columns={columns}
                                          data={props.values.mineralsOwned.map(o => ({ ...o }))}
                                          icons={{
                                            Add: props => (
                                              <div id="myPlus" ref={tableRef} className={classes.addButton} hidden>
                                              </div>
                                            ),
                                            Edit: (props) => {
                                              let rowId = minerals ? newMinerals.find(obj => !obj.edit)?.originalId : null
                                              if (rowId) {
                                                const currentObj = newMinerals.find(obj => obj.originalId === rowId);
                                                if (currentObj) currentObj.edit = true;
                                              }
                                              return (
                                                <div
                                                  ref={tableRef}
                                                  onMouseEnter={() => setMineralRowId(rowId)}
                                                  onMouseLeave={() => setMineralRowId(null)}
                                                >
                                                  <Edit
                                                    style={{
                                                      visibility: mineralRowId === rowId ? 'visible' : 'hidden',
                                                      pointerEvents: mineralRowId === rowId ? 'auto' : 'none'
                                                    }}
                                                    disabled={mineralRowId !== rowId}
                                                  />
                                                </div>
                                              );
                                            },
                                            Delete: (props) => {
                                              let rowId = minerals ? newMinerals.find(obj => !obj.delete)?.originalId : null;
                                              if (rowId) {
                                                const currentObj = newMinerals.find(obj => obj.originalId === rowId);
                                                if (currentObj) currentObj.delete = true;
                                              }
                                              return (
                                                <div
                                                  ref={tableRef}
                                                  onMouseEnter={() => setMineralRowId(rowId)}
                                                  onMouseLeave={() => setMineralRowId(null)}
                                                >
                                                  <Delete
                                                    style={{
                                                      visibility: mineralRowId === rowId ? 'visible' : 'hidden',
                                                      pointerEvents: mineralRowId === rowId ? 'auto' : 'none'
                                                    }}
                                                    disabled={mineralRowId !== rowId}
                                                  />
                                                </div>
                                              );
                                            },
                                          }}
                                          options={{
                                            rowStyle: {
                                              background: '#fafafa',
                                              borderBottom: 'none',
                                            },
                                            actionsColumnIndex: -1,
                                            headerStyle: {
                                              backgroundColor: '#1976d2',
                                              color: '#FFF',
                                              textAlign: 'top',
                                            },
                                            cellStyle: {
                                              width: 'auto',
                                              overFlowX: 'auto',

                                            },
                                            exportButton: false,
                                            addRowPosition: 'first',
                                            searchFieldAlignment: 'left',
                                            toolbarButtonAlignment: 'left',
                                            pageSize: 3,
                                            sorting: true,
                                            showTitle: false,
                                            search: false,
                                            paging: false,
                                          }}
                                          editable={{
                                            onRowAdd: (newData) => {
                                              const {
                                                netMineralAcres,
                                                mineralPercentage,
                                              } = mineralMath(newData.mineralFraction);
                                              return addMinerals({
                                                variables: {
                                                  from: { id: newData['Owner.name'].id },
                                                  to: { id: Tract.id },
                                                  data: {
                                                    id: v4(),
                                                    mineralPercentage,
                                                    netMineralAcres,
                                                    mineralFraction: newData.mineralFraction,
                                                  },
                                                },
                                              })
                                                .then(({ data: { AddOwnerMinerals } }) => {
                                                  push({
                                                    Owner: AddOwnerMinerals.from,
                                                    mineralPercentage: AddOwnerMinerals.mineralPercentage,
                                                    netMineralAcres: AddOwnerMinerals.netMineralAcres,
                                                    mineralFraction: AddOwnerMinerals.mineralFraction,
                                                    id: AddOwnerMinerals.id,
                                                  });
                                                })
                                                .catch((error) => console.log(error));
                                            },
                                            onRowUpdate: (newData, oldData) => {
                                              //Break down input with Mathjs
                                              const mineralFraction = math.fraction(newData.mineralFraction);
                                              const mineralPercentage = math.number(mineralFraction);
                                              const netMineralAcres = math.multiply(mineralPercentage, Tract.familyNMA);
                                              //create update object
                                              const ownerUpdate = {
                                                relationshipID: oldData.id,
                                                mineralFraction: newData.mineralFraction.trim(),
                                                netMineralAcres: netMineralAcres,
                                                mineralPercentage: mineralPercentage,
                                                volPage: newData.volPage,
                                                familyMember: newData.familyMember,
                                                propOwnership: newData.propOwnership,
                                              };
                                              return updateMineralOwnership(ownerUpdate).then(
                                                ({ data: { updateMineralOwnership } }) => {
                                                  remove(oldData.tableData.id);
                                                  insert(oldData.tableData.id, {
                                                    ...updateMineralOwnership,
                                                    Owner: { name: oldData.Owner.name },
                                                  });
                                                },
                                              );
                                            },
                                            onRowDelete: (oldData) => {
                                              return removeMinerals({
                                                variables: {
                                                  from: { id: oldData.Owner.id },
                                                  to: { id: Tract.id },
                                                },
                                                update: (store, { data: { RemoveOwnerMinerals } }) => {
                                                  try {
                                                    const oldMinerals = store.readFragment({
                                                      id: Tract.id,
                                                      fragment: TRACT_OWNERSHIP_FRAGMENT,
                                                    });
                                                    if (!oldMinerals || !oldMinerals.mineralsOwned) return;

                                                    const updatedMinerals = oldMinerals.mineralsOwned.filter(
                                                      (minerals) => minerals.id !== oldData.id,
                                                    );

                                                    store.writeFragment({
                                                      id: Tract.id,
                                                      fragment: TRACT_OWNERSHIP_FRAGMENT,
                                                      data: {
                                                        ...oldMinerals,
                                                        mineralsOwned: updatedMinerals,
                                                      },
                                                    });
                                                  } catch (e) {
                                                    console.error(e);
                                                  }
                                                },
                                              })
                                                .then((data) => remove(oldData.tableData.id))
                                                .catch((error) => console.log(error));
                                            },
                                          }}
                                          localization={{
                                            header: {
                                              actions: 'Actions'},
                                            body: {
                                              addTooltip: 'Add row',
                                              editTooltip: 'Edit row',
                                              deleteTooltip: 'Delete row',
                                              cancelTooltip: 'Cancel',
                                              saveTooltip: 'Save',
                                            },
                                          }}
                                        />
                                      );
                                    }}
                                  </FieldArray>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        </div>
                        <div style={{
                          display: 'flex',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          background: '#1976d2',
                          position: 'relative',
                        }}>

                            <Typography color="textSecondary"
                                        style={{
                                          marginLeft: '8px',
                                          paddingTop: '5px',
                                          color: 'white',
                                        }}
                                        gutterBottom>
                                SURFACE INTEREST
                              </Typography>
                        </div>
                          <div style={{ display: 'flex' }}>
                            <Field
                              name="grossSurfaceAcres"
                              placeholder="GSA"
                              style={{
                                marginLeft: '8px',
                                padding: '0px 0px',
                              }}
                              component={TextField}
                              label="GSA"
                              className={classes.TextField}
                            />
                            <Field
                              name="surfaceFraction"
                              placeholder="Surface Fraction"
                              component={TextField}
                              style={{
                                padding: '0px 0px',
                              }}
                              label="Surface Fraction"
                              className={classes.TextField}
                              disabled
                            />
                            <Field
                              name="finalSurfacePercent"
                              placeholder="Surface Percent"
                              style={{
                                padding: '0px 0px',
                              }}
                              component={TextField}
                              label="SRF%"
                              className={classes.TextField}
                              disabled
                            />
                            <Field
                              name="familyNSA"
                              placeholder="NSA"
                              style={{
                                padding: '0px 0px',
                              }}
                              component={TextField}
                              label="NSA"
                              className={classes.TextField}
                              disabled
                            />
                            <div>
                                  <Fade in={checkOwnership(props.values.grossSurfaceAcres)}>
                                  <div
                                    className={classes.paper}
                                    style={{ marginTop: '-55px' }}
                                  >
                                    <div>
                                      <FieldArray name="surfaceOwned">
                                        {({ remove, insert, push }) => {
                                          let surfaces = props.values.surfaceOwned.length ? props.values.surfaceOwned : null
                                          const newSurfaces = surfaces ? surfaces.map(obj => ({
                                            originalId: obj.id,
                                            edit: false,
                                            delete: false
                                          })) : null
                                          const surfaceMath = (surfaceFraction = 0) => {
                                            surfaceFraction = math.fraction(surfaceFraction);
                                            const percentage = math.number(surfaceFraction);

                                            return {
                                              surfacePercentage: percentage,
                                              netSurfaceAcres: math.multiply(percentage, Tract.familyNSA),
                                            };
                                          };

                                          const columns = [
                                            {
                                              title: (
                                                <AddIcon
                                                  style={{fontSize: '30px',
                                                    color: '#fff',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    const plusDiv = document.getElementById('surfacePlus');
                                                    if (plusDiv) {
                                                      plusDiv.click();
                                                    }
                                                  }}
                                                  fontSize="small" />
                                              ),
                                              field: 'dummy', // or any field name
                                              editable: 'never',
                                              headerStyle: {
                                                width: '1px',     // or even '20px'
                                                textAlign: 'center'
                                              },
                                              cellStyle: {
                                                whiteSpace: 'nowrap',
                                                padding: '0px 0px 0px 0px',
                                                borderBottom: 'none',
                                              },
                                              render: () => null
                                            },
                                            {
                                              title: 'Owner',
                                              field: 'Owner.name',
                                              defaultSort: 'asc',
                                              editComponent: ({ onChange }) => (
                                                <Select
                                                  options={owners}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  onChange={({ id, name }) => onChange({ id, name })}
                                                />
                                              ),
                                              editable: 'onAdd',
                                            },
                                            {
                                              title: 'Surface Percentage',
                                              field: 'surfacePercentage',
                                              render: (rowData) => (!rowData ? '0' : rowData.surfacePercentage.toFixed(8)),
                                              editable: 'never',
                                            },
                                            {
                                              title: 'Fraction',
                                              field: 'surfaceFraction',
                                            },
                                            {
                                              title: 'NSA',
                                              field: 'netSurfaceAcres',
                                              render: (rowData) => (!rowData ? '0' : rowData.netSurfaceAcres.toFixed(8)),
                                              editable: 'never',
                                            },
                                          ];

                                          return (
                                            <MaterialTable
                                              components={{
                                                Toolbar: (props) => (
                                                  <div hidden
                                                    style={{
                                                      height: "0px",
                                                    }}
                                                  >
                                                    <MTableToolbar {...props} />
                                                  </div>
                                                ),
                                              }}
                                              tableRef={tableRef}
                                              columns={columns}
                                              data={props.values.surfaceOwned.map(o => ({ ...o }))}
                                              icons={{
                                                Add: props => (
                                                  <div id="surfacePlus" ref={tableRef} className={classes.addButton} hidden>
                                                  </div>
                                                ),
                                                Edit: (props) => {
                                                  let rowId = surfaces ? newSurfaces.find(obj => !obj.edit)?.originalId : null
                                                  if (rowId) {
                                                    const currentObj = newSurfaces.find(obj => obj.originalId === rowId);
                                                    if (currentObj) currentObj.edit = true;
                                                  }
                                                  return (
                                                    <div
                                                      ref={tableRef}
                                                      onMouseEnter={() => setHoveredRowId(rowId)}
                                                      onMouseLeave={() => setHoveredRowId(null)}
                                                    >
                                                      <Edit
                                                        style={{
                                                          visibility: hoveredRowId === rowId ? 'visible' : 'hidden',
                                                          pointerEvents: hoveredRowId === rowId ? 'auto' : 'none'
                                                        }}
                                                        disabled={hoveredRowId !== rowId}
                                                      />
                                                    </div>
                                                  );
                                                },
                                                Delete: (props) => {
                                                  let rowId = surfaces ? newSurfaces.find(obj => !obj.delete)?.originalId : null;
                                                  if (rowId) {
                                                    const currentObj = newSurfaces.find(obj => obj.originalId === rowId);
                                                    if (currentObj) currentObj.delete = true;
                                                  }
                                                  return (
                                                    <div
                                                      ref={tableRef}
                                                      onMouseEnter={() => setHoveredRowId(rowId)}
                                                      onMouseLeave={() => setHoveredRowId(null)}
                                                    >
                                                      <Delete
                                                        style={{
                                                          visibility: hoveredRowId === rowId ? 'visible' : 'hidden',
                                                          pointerEvents: hoveredRowId === rowId ? 'auto' : 'none'
                                                        }}
                                                        disabled={hoveredRowId !== rowId}
                                                      />
                                                    </div>
                                                  );
                                                },


                                                // Edit: props => ( <div id="surfaceEdit" ref={tableRef} hidden></div>),
                                              }}
                                              options={{
                                                rowStyle: {
                                                  background: '#fafafa',
                                                  borderBottom: 'none',
                                                },
                                                actionsColumnWidth: '500px',
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                  backgroundColor: '#1976d2',
                                                  color: '#FFF',
                                                  textAlign: 'top',
                                                },
                                                cellStyle: {
                                                  width: 'auto',
                                                  overFlowX: 'auto',
                                                },
                                                exportButton: false,
                                                addRowPosition: 'first',
                                                searchFieldAlignment: 'left',
                                                toolbarButtonAlignment: 'left',
                                                pageSize: 3,
                                                sorting: true,
                                                showTitle: false,
                                                search: false,
                                                paging: false,
                                              }}
                                              editable={{
                                                onRowAdd: (newData) => {
                                                  const {
                                                    netSurfaceAcres,
                                                    surfacePercentage,
                                                  } = surfaceMath(newData.surfaceFraction);
                                                  return addSurface({
                                                    variables: {
                                                      from: { id: newData['Owner.name'].id },
                                                      to: { id: Tract.id },
                                                      data: {
                                                        id: v4(),
                                                        surfacePercentage,
                                                        netSurfaceAcres,
                                                        surfaceFraction: newData.surfaceFraction,
                                                      },
                                                    },
                                                  })
                                                    .then(({ data: { AddOwnerSurface } }) => {
                                                      push({
                                                        Owner: AddOwnerSurface.from,
                                                        surfacePercentage: AddOwnerSurface.surfacePercentage,
                                                        netSurfaceAcres: AddOwnerSurface.netSurfaceAcres,
                                                        surfaceFraction: AddOwnerSurface.surfaceFraction,
                                                        id: AddOwnerSurface.id,
                                                      });
                                                    })
                                                    .catch((error) => console.log(error));
                                                },
                                                onRowUpdate: (newData, oldData) => {
                                                  //Break down input with Mathjs
                                                  const surfaceFraction = math.fraction(newData.surfaceFraction.trim());
                                                  const surfacePercentage = math.number(surfaceFraction);
                                                  const netSurfaceAcres = math.multiply(surfacePercentage, Tract.familyNSA);
                                                  //create update object
                                                  const ownerUpdate = {
                                                    relationshipID: oldData.id,
                                                    surfaceFraction: newData.surfaceFraction.trim(),
                                                    netSurfaceAcres: netSurfaceAcres,
                                                    surfacePercentage: surfacePercentage,
                                                  };
                                                  return updateSurfaceOwnership(ownerUpdate).then(
                                                    ({ data: { updateSurfaceOwnership } }) => {
                                                      remove(oldData.tableData.id);
                                                      insert(oldData.tableData.id, {
                                                        ...updateSurfaceOwnership,
                                                        Owner: { name: oldData.Owner.name },
                                                      });
                                                    },
                                                  );
                                                },
                                                onRowDelete: (oldData) => {
                                                  return removeSurface({
                                                    variables: {
                                                      from: { id: oldData.Owner.id },
                                                      to: { id: Tract.id },
                                                    },
                                                    update: (store, { data: { RemoveOwnerSurface } }) => {

                                                      try {
                                                        const oldSurface = store.readFragment({
                                                          id: Tract.id,
                                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                                        });

                                                        if (!oldSurface || !oldSurface.mineralsOwned) return;

                                                        const updatedSurface = oldSurface.surfaceOwned.filter(
                                                          (surface) => surface.id !== oldData.id,
                                                        );

                                                        store.writeFragment({
                                                          id: Tract.id,
                                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                                          data: {
                                                            ...oldSurface,
                                                            surfaceOwned: updatedSurface,
                                                          },
                                                        });
                                                      } catch (e) {
                                                        console.error(e);
                                                      }
                                                    },
                                                  })
                                                    .then((data) => remove(oldData.tableData.id))
                                                    .catch((error) => console.log(error));
                                                },
                                              }}
                                              localization={{
                                                header: {
                                                  actions: 'Actions'},
                                                body: {
                                                  addTooltip: 'Add row',
                                                  editTooltip: 'Edit row',
                                                  deleteTooltip: 'Delete row',
                                                  cancelTooltip: 'Cancel',
                                                  saveTooltip: 'Save',
                                                },
                                              }}
                                            />
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  </div>
                                </Fade>


                            </div>
                        </div>
                        <div
                          style={{ marginTop: '-20px',
                          }}
                        >
                          <div
                            style={{
                              marginLeft: '25px',
                              marginRight: '25px',
                              marginTop: '25px',
                              marginBottom: '25px',
                              borderRadius: '5px',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                          >
                            <Field
                              name="legalDescription"
                              placeholder="Legal Description of Property"
                              component={TextField}
                              label={<div style={{marginLeft: '10px'}} className={'fw-bold'} > <u>Legal Description Of Property</u> </div>}
                              className={classes.TextArea}
                              fullWidth
                              multiline
                              rows={4}
                            />
                            <Field
                              name="notes"
                              placeholder="Notes"
                              component={TextField}
                              label={<div style={{marginLeft: '10px'}} className={'fw-bold'} > <u>Notes</u> </div>}
                              className={classes.TextArea}
                              fullWidth
                              multiline
                              rows={3}
                            />
                            <Field
                              name="drivingDirections"
                              placeholder="Driving Directions"
                              component={TextField}
                              label={<div style={{marginLeft: '10px'}} className={'fw-bold'} > <u>Driving Directions</u> </div>}
                              className={classes.TextArea}
                              fullWidth
                              multiline
                              rows={3}
                            />
                          </div>
                        </div>
                        {!Tract.geometry ? '' : <TractMapCard geometry={Tract.geometry} name={Tract.name} />}
                      </Form>
                    );
                }}
            </Formik>
          </MuiThemeProvider>
        </>
    );
};

export default EditTractForm;
