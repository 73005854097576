import React from 'react';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import Button from '@material-ui/core/Button';

const OtherLeaseTable = ({ data, history }) => {
  const editableData = data.map((record) => {
    const copy = { ...record };
    copy.lands =
      copy.locatedOn.length === 0
        ? <span style={{ color: 'red' }}>Error No Connection To Tract</span>
        : copy.locatedOn.map((r) => r.name).join(', ');
    return copy;
  });

  const columns = [
    {
      title: 'Lease Name',
      field: 'projectName',
      defaultSort: 'asc',
      render: (rowData) => (
        <Link
          to={`/OtherLeases/${rowData.id}?name=${encodeURIComponent(rowData.projectName)}`}
              target='_blank'>
          {rowData.projectName === ' ' ? 'None' : rowData.projectName}
        </Link>
      ),
    },
    {
      title: 'Lessee',
      field: 'lessee',
    },
    {
      title: 'Type of Lease',
      field: 'type',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Expiration Date',
      field: 'dateOfExpiration',
      render: (rowData) =>
        moment(rowData.dateOfExpiration).isValid()
          ? moment(rowData.dateOfExpiration).format('MM/DD/YYYY')
          : rowData.dateOfExpiration,
    },
    {
      title: 'Lease Date',
      field: 'dateOfLease',
      render: (rowData) =>
        moment(rowData.dateOfLease).isValid() ? moment(rowData.dateOfLease).format('MM/DD/YYYY') : rowData.dateOfLease,
    },
    {
      title: 'Land ID',
      field: 'lands',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={editableData}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              backgroundColor: '#1976d2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 16px',
            }}
          >
            <MTableToolbar {...props} />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push('/AddOtherLease')}
              style={{ marginLeft: '16px' }}
            >
              Add Lease
            </Button>
          </div>
        ),
      }}
      options={{
        searchFieldAlignment: 'left',
        exportButton: true,
        pageSize: data.length,
        searchFieldStyle: {
          border: 'none',
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '8px',
          paddingRight: '8px',
          borderRadius: '4px',
          backgroundColor: '#fff',
          color: '#000',
        },
        showTitle: false,
        toolbarButtonAlignment: 'left',
        headerStyle: {
          backgroundColor: '#1976d2',
          color: '#FFF',
          textAlign: 'left',
          justifyContent: 'center',
          whiteSpace: 'pre',
        },
      }}
    />
  );
};

export default OtherLeaseTable;