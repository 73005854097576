import React, { useRef } from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import {TextField} from 'formik-material-ui';
import {makeStyles} from '@material-ui/styles';
import {useSnackbar} from 'notistack';
import Auth from '../../util/Auth';
import { Button, MenuItem, TextField as MaterialUITextField } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import operatorSchema from '../../components/operators/OperatorSchema';
import DeleteOperatorModal from './DeleteOperatorModal';
import {Prompt} from 'react-router';
import moment from 'moment';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import AddIcon from '@material-ui/icons/Add';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

const OperatorDetailPage = ({
                              Operator,
                              onSubmit,
                              addContact,
                              removeContact,
                              updateContact,
                              deleteOperator,
                              history
                            }) => {

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);
  const tableRef = useRef(null);

  const initialValues = {
    ...Operator,
    name: Operator.name || '',
    type: Operator.type || '',
    address1: Operator.address1 || '',
    operatorNumber: Operator.operatorNumber || '',
    city: Operator.city || '',
    mesAccountNumber: Operator.mesAccountNumber || '',
    state: Operator.state || '',
    mpiAccountNumber: Operator.mpiAccountNumber || '',
    zip: Operator.zip || '',
    sroAccountNumber: Operator.sroAccountNumber || '',
    phone: Operator.phone || '',
    taxPayerID: Operator.taxPayerID || '',
    companyWebsite: Operator.companyWebsite || '',
    notes: Operator.notes || '',
    contacts: Operator.contacts || [],
    initialStatus: Operator.initialStatus || '',
    status: Operator.status || '',
  };
  const EditFormStyles = makeStyles(() => ({
    isAdmin: {
      display: hasPermissions ? '' : 'none',
    },
    labelStyle: {
      width: '15%',
      marginLeft: '6px',
      marginRight: '4px',
      color: 'rgb(163 163 163)',
      fontSize: '14px'
    },
  }));

  const classes = EditFormStyles();

  return (
    <div style={{width: 'auto'}}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={operatorSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            return onSubmit(values).then(
              (value) => {
                enqueueSnackbar('Form Successfully Edited', {variant: 'success'});
                actions.setSubmitting(false);
                closeSnackbar();
              },
              (error) => {
                enqueueSnackbar('Error Submitting Form', {variant: 'error'});
                console.log(error);
                actions.setSubmitting(false);
                closeSnackbar();
              }
            );
          }}
        >
          {(props) => {
            return (
              <>
                <Prompt
                  when={props.dirty && props.submitCount < 1}
                  message='You have not submitted the form, are you sure you want to leave?'
                />
                <Form style={{width: 'auto'}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#1976d2',
                      borderTopLeftRadius: '6px',
                      borderTopRightRadius: '6px',
                      height: '50px',
                    }}
                  >
                    <div style={{flex: 'auto'}}></div>
                    <div style={{marginLeft: '64px', flex: 'none', marginTop: '0px'}}>
                      <div style={{display: 'flex', gap: '8px', marginRight: '16px'}}>
                        <Button
                          type="button"
                          onClick={(event) => props.handleSubmit()}
                          disabled={!props.isValid}
                          tabIndex={15}
                          variant="contained"
                          color="primary"
                          size="small"
                          className={`${classes.isAdmin}`}
                          style={{
                            fontSize: '0.75rem',
                            minWidth: 'auto',
                            padding: '4px 10px',
                            border: 'none',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            borderRadius: '4px',
                          }}
                        >
                          Update
                        </Button>

                        <Button
                          type="button"
                          onClick={() => setModalOpen(true)}
                          className={`${classes.isAdmin}`}
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{
                            border: 'none',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            borderRadius: '4px',
                          }}
                        >
                          Delete
                        </Button>
                        <DeleteOperatorModal
                          open={modalOpen}
                          handleClose={() => setModalOpen(false)}
                          handleAgree={() => {
                            deleteOperator({
                              variables: {
                                id: props.values.id,
                              },
                              update: (store, {data: {DeleteOperator}}) => {
                                store.data.delete(DeleteOperator.id);
                              },
                            })
                              .then(({data}) => {
                                enqueueSnackbar('Successfully deleted Contact', {variant: 'success'});
                                history.push('/Operators');
                              })
                              .catch((err) => {
                                console.error(err);
                                enqueueSnackbar('Could not delete Contact', {variant: 'error'});
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <table style={{minWidth: '100%', marginTop: '2px'}}>
                    <tbody>
                    <tr>
                      <th style={{borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`} style={{fontWeight: 'normal'}}>
                          Company Name:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 1,
                            style: {
                              fontWeight: 'normal',
                            },
                          }}
                          name="name"
                          placeholder="Contact Name"
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </th>
                      <td style={{borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Type Dropdown:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 8 }}
                          name="type"
                          select
                          component={TextField}
                        >
                          <MenuItem value={'Attorney'}>Attorney</MenuItem>
                          <MenuItem value={'County Gov'}>County Gov</MenuItem>
                          <MenuItem value={'Education'}>Education</MenuItem>
                          <MenuItem value={'Family Contact'}>Family Contact</MenuItem>
                          <MenuItem value={'Family Office'}>Family Office</MenuItem>
                          <MenuItem value={'Lessee'}>Lessee</MenuItem>
                          <MenuItem value={'O&G'}>O&G</MenuItem>
                          <MenuItem value={'Office/Business'}>Office/Business</MenuItem>
                          <MenuItem value={'Regulatory'}>Regulatory</MenuItem>
                          <MenuItem value={'ROW'}>ROW</MenuItem>
                          <MenuItem value={'Surface Contractor'}>Surface Contractor</MenuItem>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Address:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 2 }}
                          name='address1'
                          placeholder='Address'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Operator #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 9 }}
                          name='operatorNumber'
                          placeholder='Operator #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          City:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 3 }}
                          name='city'
                          placeholder='City'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          MES Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 10 }}
                          name='mesAccountNumber'
                          placeholder='MES Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          State:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 4 }}
                          name='state'
                          placeholder='State'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          MPI Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 11 }}
                          name='mpiAccountNumber'
                          placeholder='MPI Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Zip Code:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 5 }}
                          name='zip'
                          placeholder='Zip Code'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          SRO Account #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 12 }}
                          name='sroAccountNumber'
                          placeholder='SRO Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Phone #:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 6 }}
                          name='phone'
                          placeholder='Phone #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Tax Payer ID-CONG:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 13 }}
                          name='taxPayerID'
                          placeholder='Tax Payer ID-CONG'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label className={`${classes.labelStyle}`}>
                          Website:
                        </label>
                        <Field
                          inputProps={{ tabIndex: 7 }}
                          name='companyWebsite'
                          placeholder='Company Website'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div style={{marginTop: '2px'}}>
                    <label className={`${classes.labelStyle}`} style={{color: 'black'}}>
                      Notes:
                    </label>
                    <Field
                      inputProps={{ tabIndex: 14 }}
                      name='notes'
                      placeholder='Notes'
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                        },
                      }}
                      component={TextField}
                      style={{

                        backgroundColor: 'rgb(255,255,255)',
                      }}
                      multiline={true}
                      rows={4}
                      variant='filled'
                      fullWidth
                    />
                  </div>
                  <FieldArray name="contacts">
                    {({ unshift}) => {
                      const columns = [
                        {
                          title: 'Current Y/N',
                          field: 'current',
                          lookup: { Yes: 'Yes', No: 'No' },
                          headerStyle: { width: '1%', maxWidth: 1 },
                          editComponent: ({ value, onChange }) => (
                            <MaterialUITextField
                              select
                              value={value || 'No'}
                              onChange={(e) => onChange(e.target.value)}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </MaterialUITextField>
                          )
                        },
                        {
                          title: 'Name',
                          field: 'contactName',
                        },
                        {
                          title: 'Position',
                          field: 'position',
                        },
                        {
                          title: 'Direct Number',
                          field: 'directLine',
                          cellStyle: { width: '11%'},
                          headerStyle: { width: '11%'},
                        },
                        {
                          title: 'E-Mail',
                          field: 'email',
                          cellStyle: {
                            overflowX: 'auto',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            maxWidth: 20,
                          },
                        },
                        {
                          title: 'Cell Number',
                          field: 'cell',
                          cellStyle: { width: '11%'},
                          headerStyle: { width: '11%'},
                        },
                        {
                          title: 'Date Entered',
                          field: 'date',
                          type: 'date',
                          dateSetting: { locale: 'en-US' },
                          editComponent: ({ value, onChange }) => (
                            <MaterialUITextField
                              type="date"
                              value={value ?? ''}
                              onChange={(e) => onChange(e.target.value) || null}
                              InputLabelProps={{ shrink: true }}
                            />
                          ),
                          render: rowData => rowData.date
                            ? moment(rowData.date).format('MM/DD/YYYY')
                            : 'N/A'
                        },
                        {
                          title: 'Notes',
                          field: 'notes',
                        },
                        {
                          title: (
                            <AddIcon
                              style={{fontSize: '30px',
                                color: '#fff',
                                float: 'right',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const plusDiv = document.getElementById('myPlus');
                                if (plusDiv && hasPermissions) {
                                  plusDiv.click();
                                }
                              }}
                              fontSize="small" />
                          ),
                          field: 'Actions', // or any field name
                          editable: 'never',
                          cellStyle: { width: '0%',
                            maxWidth: 0,
                            display: hasPermissions ? '' : 'none',
                          },
                          render: () => null
                        },
                      ];

                      return (
                        <div>
                          <MaterialTable
                            columns={columns}
                            // data={props.values.contacts.map((o) => ({ ...o }))}
                            // Remember to do stuff like this, sanitize the data beforehand, avoids errors.
                            data={props.values.contacts.map(contact => ({
                              ...contact,
                              current: contact.current || 'No',
                              date: contact.date || '',
                              contactName: contact.contactName || '',
                              position: contact.position || '',
                              directLine: contact.directLine || '',
                              cell: contact.cell || '',
                              email: contact.email || '',
                              notes: contact.notes || ''
                            }))}
                            title={'Contact'}
                            // IF WE NEED EXPORT THEN JUST COMMENT COMPONENTS OUT
                            components={{
                              Toolbar: (props) => (
                                <div
                                  hidden
                                  style={{
                                    height: "0px",
                                  }}
                                >
                                  <MTableToolbar {...props} />
                                </div>
                              ),
                            }}
                            tableRef={tableRef}
                            icons={{
                              Add: props => (
                                <div id="myPlus" ref={tableRef} hidden>
                                </div>
                              ),
                            }}
                            options={{
                              tableLayout: 'auto',
                              actionsColumnIndex: -1,
                              addRowPosition: 'first',
                              rowId: 'id',
                              sorting: true,
                              headerStyle: {
                                backgroundColor: '#1976d2',
                                color: '#FFF',
                                textAlign: 'top',
                              },
                              cellStyle: {
                                width: 'auto',
                                overflowX: 'auto',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                              },
                              exportButton: true,
                              exportAllData: true,
                              pageSize: 5,
                              toolbarButtonAlignment: 'left',
                              searchFieldAlignment: 'left',
                              exportFileName: 'Contacts',
                            }}
                            editable={hasPermissions ? {
                              onRowAdd: (newData) => {
                                return new Promise((resolve, reject) => {
                                  const newContact = {
                                    operatorID: Operator.id,
                                    contactName: newData.contactName || '',
                                    position: newData.position || '',
                                    directLine: newData.directLine || '',
                                    cell: newData.cell || '',
                                    email: newData.email || '',
                                    current: newData.current || 'No',
                                    date: newData.date || '',
                                    notes: newData.notes || '',
                                  };

                                  addContact(newContact)
                                    .then(({ data: { createNewContact } }) => {
                                      const updatedData = { ...newContact, id: createNewContact.id };
                                      unshift(updatedData); // Add to Formik array
                                      resolve();
                                    })
                                    .catch(error => {
                                      console.error('Add failed:', error);
                                      enqueueSnackbar('Add failed', { variant: 'error' });
                                      reject(error);
                                    });
                                });
                              },
                              onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                  updateContact(newData)
                                    .then(() => {
                                      const updatedContacts = props.values.contacts.map(item =>
                                        item.id === oldData.id ? newData : item
                                      );
                                      props.setFieldValue('contacts', updatedContacts);
                                      resolve();
                                    })
                                    .catch(error => {
                                      console.error('Update failed:', error);
                                      enqueueSnackbar('Update failed', { variant: 'error' });
                                      reject(error);
                                    });
                                }),
                              onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                  removeContact(oldData.id)
                                    .then(() => {
                                      const filteredContacts = props.values.contacts.filter(
                                        item => item.id !== oldData.id
                                      );
                                      props.setFieldValue('contacts', filteredContacts);
                                      resolve();
                                    })
                                    .catch(error => {
                                      console.error('Delete failed:', error);
                                      enqueueSnackbar('Delete failed', { variant: 'error' });
                                      reject(error);
                                    });
                                })
                            }  : {}}
                          />
                        </div>
                      );
                    }}
                  </FieldArray>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default OperatorDetailPage;